import React from 'react'
import { FilterStore } from '@core/providers/filters'
import { Filters } from './Filters'
import { FilterMapInternal } from '@core/providers/filters'
import { useDisciplineFilter } from '@core/providers/filters/useDisciplineFilter'
import { useQueryParamFilterStore } from './store'
import { FilterInternalArguments } from '@core/providers/filters'

type DisciplineFiltersProps = {
  organisationId: number
  filters: { [key: string]: FilterInternalArguments }
  store: FilterStore<FilterMapInternal>
}

export const DisciplineFilters: React.FC<DisciplineFiltersProps> = ({
  organisationId,
  filters,
  store,
}) => {
  const disciplineFilters = {
    discipline: useDisciplineFilter({ organisationId, filters, store }),
  }
  const disciplineFilterStore = useQueryParamFilterStore({
    filters: disciplineFilters,
    queryParams: {
      discipline: 'discipline',
    },
  })

  return (
    <Filters noFlex filters={disciplineFilters} store={disciplineFilterStore} />
  )
}
