import {
  createMutationKeys,
  createQueryKeys,
  mergeQueryKeys,
} from '@lukemorales/query-key-factory'
import { APIAxiosInstance } from '@core/utils/api-axios-instance'
import { RequestTypes } from './requestTypes'
import { createQueryKeyString } from '@core/react-query/queryParams'
import { uploadProofs } from '../permits/permit/helpers'
import { PageResponse } from '@core/entities/pagination'
import { IComment } from '@core/entities/comments'
import { getMediaUrlContentType } from '@core/react-query/helpers/mediaHelpers'

export const commentsQueryKeys = createQueryKeys('commentsKeys', {
  getComments: ({
    id,
    type,
    subType,
    subTypeId,
    subTypeIdText,
    page,
    size,
    sort,
  }: RequestTypes['getComments']) => {
    return {
      queryKey: [
        { id, type },
        {
          subType,
          subTypeId,
          subTypeIdText,
        },
        page,
        size,
        sort,
      ],
      queryFn: async () => {
        const queryParams = createQueryKeyString({
          page,
          size,
          sort,
          id,
          type,
          subType,
          subTypeId,
          subTypeIdText,
        })
        const { data } = await APIAxiosInstance.get<PageResponse<IComment>>(
          `comments${queryParams}`,
        )

        await Promise.all(
          data.content.map(async (comment, i) => {
            await Promise.all(
              comment.mediaUrls.map(async (mediaUrl) => {
                const attachmentType = await getMediaUrlContentType(mediaUrl)

                if (!comment.mediaUrlsWithType) comment.mediaUrlsWithType = []

                comment.mediaUrlsWithType.push({
                  getUrl: mediaUrl,
                  type: attachmentType,
                })
              }),
            )
          }),
        )

        return data
      },
    }
  },
})

const commentsKeysMutationsKeys = createMutationKeys('commentsKeys', {
  createComment: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['createComment']) => {
      const attachmentUploads = uploadProofs(
        payload.attachmentsKeys,
        `${payload.organisationId}-${payload.referenceObjectId}-comment`,
      )
      const uploadedAttachments = await Promise.all(attachmentUploads)
      const uploadedAttachmentsKeys = uploadedAttachments?.map(
        (attachment) => attachment.key,
      )

      const { data } = await APIAxiosInstance.post(`comments`, {
        ...payload,
        attachmentsKeys: uploadedAttachmentsKeys,
      })

      return data
    },
  },
})

export const commentsKeys = mergeQueryKeys(
  commentsQueryKeys,
  commentsKeysMutationsKeys,
).commentsKeys
