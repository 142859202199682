import {
  createMutationKeys,
  createQueryKeys,
  mergeQueryKeys,
} from '@lukemorales/query-key-factory'
import { RequestTypes } from './requestTypes'
import { APIAxiosInstance } from '@core/utils/legacy-api-axios-instance'
import { createQueryKeyString } from '@core/react-query/queryParams'
import { cloneDeep } from '@core/lodash'

export const projectQueryKeys = createQueryKeys('project', {
  byId: ({ projectId }: RequestTypes['getProject']) => {
    return {
      queryKey: [projectId],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(`projects/${projectId}`)
        return data
      },
      contextQueries: {
        projectDashboard: ({
          projectId,
          templateIds,
          locationIds,
        }: RequestTypes['getProjectDashboard']) => ({
          queryKey: [projectId, templateIds, locationIds],
          queryFn: async () => {
            const queryString = createQueryKeyString({
              projectId,
              templateIds,
              locationIds,
            })

            const { data } = await APIAxiosInstance.get(
              `permits/dashboard${queryString}`,
            )
            return data
          },
        }),
        projectUsers: (payload: RequestTypes['getProjectUsers']) => ({
          queryKey: [
            payload.page,
            payload.size,
            payload.term,
            payload.authGroupsIds,
            payload.roles,
          ],
          queryFn: async () => {
            const payloadCopy = cloneDeep(payload)
            if (payloadCopy?.authGroupsIds?.length === 0) {
              delete payloadCopy.authGroupsIds
            }
            const queryString = createQueryKeyString(payloadCopy)
            const { data } = await APIAxiosInstance.get(
              `projects/${projectId}/users${queryString}`,
            )
            return data
          },
        }),
        projectUser: ({ userId }) => ({
          queryKey: [userId],
          queryFn: async () => {
            const { data } = await APIAxiosInstance.get(
              `projects/${projectId}/users/${userId}`,
            )
            return data
          },
        }),
        projectRegisters: ({ page, size, term, projectId }: any) => ({
          queryKey: [projectId, page, size, term],
          queryFn: async () => {
            // TODO: move all the register related stuff to the registers domain
          },
        }),
        projectPermitTemplates: (
          payload: RequestTypes['getProjectPermitTemplates'],
        ) => {
          return {
            queryKey: [payload.projectId, payload.page, payload.size],
            queryFn: async () => {
              const queryString = createQueryKeyString(payload)
              const { data } = await APIAxiosInstance.get(
                `permits/templates/project${queryString}`,
              )

              return data
            },
          }
        },
      },
    }
  },
})

export const projectMutationKeys = createMutationKeys('project', {
  setProjectUserRole: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['setProjectUserRoles']) => {
      const { projectId, userId, roles } = payload
      const { data } = await APIAxiosInstance.put(
        `projects/${projectId}/users/${userId}/roles`,
        {
          roles,
        },
      )
      return data
    },
  },
  setProjectUserAuthGroups: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['setProjectUserAuthGroups']) => {
      const { projectId, userId, authorisationGroups } = payload
      const { data } = await APIAxiosInstance.put(
        `projects/${projectId}/users/${userId}/authorisation-groups`,
        {
          authorisationGroups,
        },
      )
      return data
    },
  },
  setProjectUserStatus: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['setProjectUserStatus']) => {
      const { projectId, userId, status } = payload
      const { data } = await APIAxiosInstance.put(
        `projects/${projectId}/users/${userId}/status?status=${status}`,
      )
      return data
    },
  },
  deleteProjectUser: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['deleteProjectUser']) => {
      const { projectId, userId } = payload
      const { data } = await APIAxiosInstance.delete(
        `projects/${projectId}/users/${userId}`,
      )
      return data
    },
  },
  inviteProjectUser: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['inviteProjectUser']) => {
      const { projectId } = payload
      const { data } = await APIAxiosInstance.post(
        `projects/${projectId}/invites`,
        {
          ...payload,
        },
      )
      return data
    },
  },
  updateProject: {
    mutationKey: null,
    mutationFn: async ({
      projectId,
      ...rest
    }: RequestTypes['updateProject']) => {
      const { data } = await APIAxiosInstance.put(`projects/${projectId}`, rest)
      return data
    },
  },
  createProject: {
    mutationKey: null,
    mutationFn: async ({
      organisationId,
      ...rest
    }: RequestTypes['createProject']) => {
      const { data } = await APIAxiosInstance.post(
        `projects?organisationId=${organisationId}`,
        rest,
      )
      return data
    },
  },
  addProjectPermitTemplates: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['addProjectPermitTemplates']) => {
      const queryString = createQueryKeyString(payload)
      const { data } = await APIAxiosInstance.put(
        `permits/templates/assign${queryString}`,
      )

      return data
    },
  },
  deleteProjectPermitTemplate: {
    mutationKey: null,
    mutationFn: async (
      payload: RequestTypes['deleteProjectPermitTemplate'],
    ) => {
      const queryString = createQueryKeyString(payload)
      const { data } = await APIAxiosInstance.delete(
        `${Project.api}permits/templates/remove${queryString}`,
      )

      return data
    },
  },
  addProjectChecklistTemplates: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['addProjectPermitTemplates']) => {
      const { data } = await APIAxiosInstance.post(
        `projects/${payload.projectId}/checklists`,
        payload,
      )

      return data
    },
  },
  deleteProjectChecklistTemplates: {
    mutationKey: null,
    mutationFn: async (
      payload: RequestTypes['deleteProjectPermitTemplate'],
    ) => {
      const { data } = await APIAxiosInstance.request({
        method: 'DELETE',
        url: `projects/${payload.projectId}/checklists`,
        data: payload,
      })

      return data
    },
  },
})

export const projectKeys = mergeQueryKeys(
  projectQueryKeys,
  projectMutationKeys,
).project
