import { localizedStrings } from '@core/strings'
import React from 'react'
import { Box, Text, Flex } from '@fundamentals'
import { Card, Chip, LinearProgress } from '@common/material'
import { Divider } from '@mui/material'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import moment from 'moment/moment'
import { useModal } from '@common/GlobalModal'
import ChecklistDetailsModal from '@modules/permitDetails/modals/ChecklistDetailsModal'
import { usePermitTasks } from '@core/react-query/features/permits/permit'
import { Permit } from '@core/entities/permit/Permit'
import { useToast } from '@core/toast'
import { useNavigationContext } from '@common/hooks'

type PermitTasksTabProps = {
  permit: Permit
}

export const PermitTasksTab: React.FC<PermitTasksTabProps> = ({ permit }) => {
  const { organisationId } = useNavigationContext()
  const toast = useToast()
  const { data: tasks, isFetching } = usePermitTasks(
    { permitId: permit?.id },
    {
      onError: (error) => toast.error(error?.message),
    },
  )
  const { showModal } = useModal()

  return (
    <Box>
      {isFetching && <LinearProgress />}
      {tasks?.map((task) => {
        return (
          <Card
            key={task.id}
            sx={{
              px: 3,
              py: 2,
              mb: 2,
              cursor:
                task.type === 'PERMIT_PRE_START_CHECKLIST'
                  ? 'pointer'
                  : 'default',
            }}
            onClick={() => {
              if (task.type === 'PERMIT_PRE_START_CHECKLIST') {
                showModal(
                  <ChecklistDetailsModal
                    checklistId={task.checklistId}
                    organisationId={organisationId}
                  />,
                )
              }
            }}
          >
            <Flex>
              <Box flex={1}>
                <Text fontWeight='bold' fontSize='1.2rem'>
                  {task.name}
                </Text>
                <Chip
                  size='small'
                  label={task.getStatusText(task.status)}
                  color={task.getStatusColor()}
                />
              </Box>
              <Box flex={1}>
                <Text sx={{ fontSize: 12 }} color='text.secondary'>
                  {localizedStrings.assignTo}
                </Text>
                <Text>
                  {`${task.assignedTo?.firstName} ${task.assignedTo?.lastName}`}
                </Text>
              </Box>
              <Box flex={1}>
                {task.status === 'TO_DO' && (
                  <Flex alignItems='flex-start' justifyContent='flex-end'>
                    <InfoOutlinedIcon fontSize='small' color='info' />
                    <Text color='info.main' sx={{ ml: 1, fontSize: 14 }}>
                      {localizedStrings.mustBeCompletedOnMobile}
                    </Text>
                  </Flex>
                )}
              </Box>
            </Flex>
            <Divider sx={{ my: 2 }} />
            <Flex>
              <Box flex={1}>
                <Text sx={{ fontSize: 14 }} color='text.secondary'>
                  {`Updated ${moment(task?.modifiedOn).fromNow()}`}
                </Text>
              </Box>
              <Box flex={1}>
                <Text sx={{ fontSize: 14 }} color='text.secondary'>
                  <EventAvailableIcon fontSize='small' />
                  {moment(task?.modifiedOn).format('Do MMM YYYY HH:mm')}
                </Text>
              </Box>
              <Box flex={1} />
            </Flex>
          </Card>
        )
      })}
      {(!tasks || tasks.length === 0) && (
        <Card sx={{ px: 3, py: 2, mb: 2 }}>
          <Text>{localizedStrings.noTasksFound}</Text>
        </Card>
      )}
    </Box>
  )
}
