import { localizedStrings } from '@core/strings'
import React, { useMemo } from 'react'
import { ControllerRenderProps } from 'react-hook-form'
import { Box, BoxProps, Flex, Text, Image } from '@fundamentals'
import Dropzone from 'react-dropzone'
import { Button, Card } from '@common/material'
import { useModal } from '@common/GlobalModal'
import { PdfModal } from '@common/PdfModal'
import produce from 'immer'
import ImageModal from '@common/modal/ImageModal'
import { styled } from '@mui/material/styles'
import { AttachmentTypes } from '@core/components/templateAnswersForm'
import {
  SelectSiteBoxDocumentModal,
  SiteBoxDocumentSelectEntry,
} from '@modules/siteBox/modals/SelectSiteBoxDocumentModal'
import { useNavigationContext } from '@common/hooks'
import useFeatureFlags from '@core/providers/useFeatureFlags'

type AttachmentInputProps = BoxProps & {
  enableLinkSiteBoxDocuments?: boolean
  inputProps?: Partial<ControllerRenderProps>
  error?: string
  maxFileSize?: number
  readOnly?: boolean
  attachmentTypes?: AttachmentTypes[]
  required?: boolean
  heading?: string
  borderLess?: boolean
  size?: 'small' | 'large'
  projectId?: number
}

export type Proof = {
  id: number
  key?: string
  type: string
  uri?: string
  siteBoxDocumentVersionId?: number
  downloadUrl: string
  getUrl: string
}

const StyledAttachmentPreview = styled(Box)<{
  size: 'small' | 'large'
}>`
  overflow: hidden;
  bgcolor: black;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  ${({ size }) => {
    if (size === 'small')
      return `
      height: 100px;
      width: 150px;
    `
    return `
    height: 200px;
    width: 300px;
  `
  }}

  & img {
    height: 100%;
    object-fit: scale-down;
  }
`

const AttachmentInput: React.FC<AttachmentInputProps> = ({
  enableLinkSiteBoxDocuments = false,
  inputProps: { value = [], onChange, ...inputProps } = {},
  readOnly = false,
  attachmentTypes,
  required,
  dataTest,
  heading,
  borderLess,
  size = 'large',
  projectId,
  ...props
}) => {
  const { flags } = useFeatureFlags(['web_site_box'])
  const { organisationId } = useNavigationContext()
  const { showModal, hideModal } = useModal()
  const [localValue, setLocalValue] = React.useState<
    Array<
      Blob & {
        uri?: string
        key?: string
        id?: number
        siteBoxDocumentVersionId?: number
      }
    >
  >(value)

  const acceptTypes = useMemo(() => {
    const types = {}
    attachmentTypes?.forEach((attachmentInput) => {
      if (attachmentInput === AttachmentTypes.IMAGE) {
        types['image/png'] = ['.png']
        types['image/jpeg'] = ['.jpeg']
        types['image/jpg'] = ['.jpg']
      }
      if (attachmentInput === AttachmentTypes.PDF) {
        types['application/pdf'] = ['.pdf']
      }
      if (attachmentInput === AttachmentTypes.CSV) {
        types['text/csv'] = ['.csv']
      }
    })
    return types
  }, [attachmentTypes])

  const onDrop = (newAttachments: Array<Blob & { uri?: string }>) => {
    const newProofsWithUri = newAttachments.map((file) => {
      file.uri = URL.createObjectURL(file)
      return file
    })

    const initValue = value.length === localValue.length ? localValue : value

    const newFiles = [...initValue, ...newProofsWithUri]
    setLocalValue(newFiles)
    onChange(newFiles)
  }

  const handleOnAddSiteBoxDocuments = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation()

    const currentSelectedSiteBoxDocuments: SiteBoxDocumentSelectEntry[] = value
      .filter(
        (proof) => proof.siteBoxDocumentVersionId || proof.siteBoxDocument,
      )
      .map((proof) => {
        const siteBoxDocumentSelectEntry: SiteBoxDocumentSelectEntry = {
          id: proof.id ?? proof?.siteBoxDocument?.id,
          key: proof.key,
          siteBoxDocumentVersionId:
            proof.siteBoxDocumentVersionId ??
            proof?.siteBoxDocument?.currentVersion?.id,
          type: proof.type,
          downloadUrl: proof.downloadUrl,
          getUrl: proof.getUrl,
        }
        return siteBoxDocumentSelectEntry
      })

    showModal(
      <SelectSiteBoxDocumentModal
        organisationId={organisationId}
        projectId={projectId}
        multiSelect={true}
        currentSelectedSiteBoxDocuments={currentSelectedSiteBoxDocuments}
        handleSiteBoxDocumentSelection={(
          siteboxDocuments: SiteBoxDocumentSelectEntry[],
        ) => {
          const newProofs: Proof[] = siteboxDocuments.map((siteboxDocument) => {
            return {
              id: siteboxDocument.id,
              key: siteboxDocument.key,
              siteBoxDocumentVersionId:
                siteboxDocument.siteBoxDocumentVersionId,
              type: siteboxDocument.type,
              downloadUrl: siteboxDocument.downloadUrl,
              getUrl: siteboxDocument.getUrl,
            }
          })

          const newValue = produce(localValue, (draft) => {
            // Create a list of indices to remove
            const indicesToRemove = []
            draft.forEach((proof: any, index) => {
              if (proof.siteBoxDocumentVersionId || proof.siteBoxDocument) {
                if (!newProofs.some((newProof) => newProof.id === proof.id)) {
                  indicesToRemove.push(index)
                }
              }
            })

            // Remove indices from the end to avoid disrupting the order
            for (let i = indicesToRemove.length - 1; i >= 0; i--) {
              draft.splice(indicesToRemove[i], 1)
            }

            // Add new proofs if they are not already included
            newProofs.forEach((newProof) => {
              if (!draft.some((proof) => proof.id === newProof.id)) {
                draft.push(newProof as any)
              }
            })
          })

          setLocalValue(newValue)
          onChange(newValue)
        }}
      />,
    )
  }

  const deleteAttachment = (attachment: { key?: string; uri?: string }) => {
    const newValue = produce(localValue, (draft) => {
      return draft.filter(({ key, uri }) => {
        if (attachment.key) return attachment.key !== key
        return attachment.uri !== uri
      })
    })
    setLocalValue(newValue)
    onChange(newValue)
  }

  if (readOnly && !value?.length) return null

  return (
    <Box {...props}>
      {!!heading && (
        <Flex color='grey.600' alignItems='center'>
          <Text fontSize='14px' mb={1}>
            {heading}
          </Text>
          {required && (
            <Text display='flex' fontStyle='italic' fontSize={13} ml={0.5}>
              (Required)
              <Text color='error.main'>*</Text>
            </Text>
          )}
        </Flex>
      )}
      {!readOnly && (
        <Dropzone accept={acceptTypes} onDrop={onDrop} maxFiles={5}>
          {({ getRootProps, getInputProps }) => {
            return (
              <Flex
                height={120}
                border='1px dashed'
                borderColor={'silver'}
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
                borderRadius={1}
                mb={1.5}
                {...getRootProps()}
              >
                <Flex
                  flexDirection='row'
                  justifyContent='center'
                  alignItems='center'
                >
                  <Button variant='contained' sx={{ width: 150 }}>
                    {localizedStrings.upload}
                    <input hidden {...getInputProps()} data-test={dataTest} />
                  </Button>

                  {flags.web_site_box && enableLinkSiteBoxDocuments && (
                    <Button
                      variant='outlined'
                      sx={{ width: 250, ml: 1 }}
                      onClick={handleOnAddSiteBoxDocuments}
                    >
                      {localizedStrings.chooseFromSitebox}
                    </Button>
                  )}
                </Flex>

                <Text color='grey' mt={1}>
                  {localizedStrings.attachmentInputDragPrompt}
                </Text>
              </Flex>
            )
          }}
        </Dropzone>
      )}
      <Flex flexWrap='wrap' sx={{ m: -0.77 }}>
        {value?.map((proof, index) => {
          return (
            <Card
              key={index}
              sx={{
                p: borderLess ? 0 : 2,
                m: 0.75,
                border: borderLess ? 'none' : 'auto',
              }}
            >
              <StyledAttachmentPreview
                size={size}
                data-test={`upload-thumbnail-${index}`}
              >
                {proof.type?.includes('image') && (
                  <Box
                    onClick={() => {
                      showModal(
                        <ImageModal
                          title={localizedStrings.image}
                          previewUrl={
                            proof.uri || proof.downloadUrl || proof.getUrl
                          }
                          s3Key={proof?.key}
                          canDownload
                        />,
                      )
                    }}
                    height='100%'
                  >
                    <Image
                      src={proof.uri || proof.downloadUrl || proof.getUrl}
                      sx={{
                        pointerEvents: 'none',
                        objectFit: 'cover',
                        height: '100%',
                        width: '100%',
                      }}
                    />
                  </Box>
                )}

                {proof.type?.includes('pdf') &&
                  !proof?.siteBoxDocumentVersionId && (
                    <Box
                      onClick={() => {
                        showModal(
                          <PdfModal
                            readOnly={readOnly}
                            title={localizedStrings.pdf}
                            previewUrl={
                              proof.uri || proof.getUrl || proof.downloadUrl
                            }
                            onAnnotate={(blob, url) => {
                              const newProofs = produce(localValue, (draft) => {
                                draft[index] = blob
                                draft[index].uri = url
                              })
                              setLocalValue(newProofs)
                              onChange(newProofs)
                              hideModal()
                            }}
                            footerActions={
                              !!proof.downloadUrl && (
                                <a href={proof.downloadUrl}>
                                  <Button variant='contained'>
                                    {localizedStrings.download}
                                  </Button>
                                </a>
                              )
                            }
                          />,
                        )
                      }}
                      height='100%'
                    >
                      <embed
                        src={proof.uri || proof.getUrl || proof.downloadUrl}
                        style={{ pointerEvents: 'none' }}
                        type='application/pdf'
                        height='100%'
                        width='100%'
                      />
                    </Box>
                  )}

                {proof.type?.includes('pdf') &&
                  proof?.siteBoxDocumentVersionId && (
                    <Box
                      onClick={() => {
                        showModal(
                          <PdfModal
                            readOnly={true}
                            title={localizedStrings.pdf}
                            previewUrl={
                              proof.uri || proof.getUrl || proof.downloadUrl
                            }
                          />,
                        )
                      }}
                      height='100%'
                    >
                      <embed
                        src={proof.uri || proof.getUrl || proof.downloadUrl}
                        style={{ pointerEvents: 'none' }}
                        type='application/pdf'
                        height='100%'
                        width='100%'
                      />
                    </Box>
                  )}
              </StyledAttachmentPreview>
              {!readOnly && (
                <Button
                  variant='outlined'
                  color='error'
                  onClick={() => deleteAttachment(proof)}
                  sx={{ mt: 1.5, float: 'right' }}
                  data-test={`upload-thumbnail-delete-btn-${index}`}
                >
                  {localizedStrings.delete}
                </Button>
              )}
            </Card>
          )
        })}
      </Flex>
    </Box>
  )
}

export { AttachmentInput }
