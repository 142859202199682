import { localizedStrings } from '@core/strings'
import React, { FC, useMemo, useState } from 'react'
import { Collapse } from '@mui/material'
import { Flex, Box, Text } from '@fundamentals'
import { Button } from '@common/material'
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@mui/icons-material'
import { Image } from '@fundamentals'
import { AuditResult } from '@core/entities/audit/AuditResult'
import { TimelineItemBase } from '@common/Timeline/TimelineItemBase'
import { AttachmentInput } from '@elements/Questions/AttachmentInput'
import { useGetChecklistById } from '@core/react-query/features/checklists/checklist'

export type ChecklistTimelineItemProps = {
  checklistId?: number
  timelineItem: AuditResult
}

export const ChecklistTimelineItem: FC<ChecklistTimelineItemProps> = ({
  timelineItem,
  checklistId,
}) => {
  const { data: checklistData } = useGetChecklistById(
    { checklistId },
    {
      refetchOnMount: false,
    },
  )
  const [expanded, setExpanded] = useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const commentQuestion = useMemo(() => {
    // eslint-disable-next-line guard-for-in
    for (const questionGroupIndex in checklistData?.mainAnswers.answers || []) {
      const questionGroup =
        checklistData?.mainAnswers.answers[questionGroupIndex]
      // eslint-disable-next-line guard-for-in
      for (const questionIndex in questionGroup.data) {
        const question = questionGroup.data[questionIndex]
        if (
          question.id ===
          timelineItem.metadata.commentSecondaryReferenceObjectIdText
        ) {
          return {
            groupIndex: parseInt(questionGroupIndex),
            answerIndex: parseInt(questionIndex),
            questionNumber: parseInt(questionIndex) + 1,
            answer: question,
          }
        }
      }
    }
  }, [])

  return (
    <TimelineItemBase
      timelineItem={timelineItem}
      commentQuestion={commentQuestion}
    >
      {timelineItem.metadata?.checklistRoleName && (
        <Text fontWeight='bold' fontSize={12}>
          {timelineItem.metadata?.checklistRoleName}
        </Text>
      )}

      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <Flex flexWrap={'wrap'} my={2}>
          {timelineItem.metadata.mediaUrlsWithType?.length > 0 && (
            <AttachmentInput
              size='small'
              mt={-1}
              inputProps={{
                value: timelineItem.metadata.mediaUrlsWithType,
              }}
              readOnly
              borderLess
            />
          )}
        </Flex>

        {timelineItem.signatureUrl && (
          <Image mt={2} src={timelineItem.signatureUrl} width={170} />
        )}
      </Collapse>

      {(timelineItem.signatureUrl ||
        timelineItem.metadata.mediaUrls.length > 0) && (
        <Box>
          <Button
            variant='text'
            size='medium'
            endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            onClick={handleExpandClick}
            sx={{
              mb: 1,
              mt: timelineItem.metadata.commentAnsweredQuestionDataResult
                ? 0
                : 1,
              ml: '-8px',
              py: 0,
            }}
          >
            {expanded ? localizedStrings.hideAll : localizedStrings.showInfo}
          </Button>
        </Box>
      )}
    </TimelineItemBase>
  )
}
