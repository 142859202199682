const colours = {
  borderColour: '#7302fd',
  selectedFillColour: '#ff975b',
  unselectedFillColour: '#835bfc',
}

export const existingZoneFillStyleWeb = (hovered: boolean) => ({
  'fill-color': hovered
    ? colours.selectedFillColour
    : colours.unselectedFillColour,
  'fill-opacity': hovered ? 0.7 : 0.5,
})

export const existingZoneLineStyleWeb = (hovered?: boolean) => ({
  'line-color': colours.borderColour,
  'line-gap-width': hovered ? 1 : 0,
  'line-width': hovered ? 2 : 3,
  'line-opacity': 1,
})

export const existingZoneExtrusionStyleWeb = () => ({
  'fill-extrusion-color': '#ff975b',
  'fill-extrusion-opacity': 0.7,
  'fill-extrusion-height': 1,
})

export const existingZoneFillStyleMobile = (hovered: boolean) => ({
  fillColor: hovered
    ? colours.selectedFillColour
    : colours.unselectedFillColour,
  fillOpacity: hovered ? 0.7 : 0.5,
})

export const existingZoneLineStyleMobile = (hovered?: boolean) => ({
  lineColor: colours.borderColour,
  lineGapWidth: hovered ? 1.5 : 0,
  lineWidth: hovered ? 2 : 3,
  lineOpacity: 1,
})

export const existingZoneFillExtrusionStyleMobile = () => ({
  fillExtrusionColor: '#ff975b',
  fillExtrusionOpacity: 0.7,
  fillExtrusionHeight: 1,
})

export const newZoneFillStyleMobile = (hovered?: boolean) => ({
  fillColor: hovered ? '#28eaa8' : 'orange',
  fillOpacity: 0.2,
})

export const newZoneLineStyleMobile = (hovered?: boolean) => ({
  lineColor: 'orange',
  lineGapWidth: hovered ? 1.5 : 0,
  lineWidth: hovered ? 2 : 3,
  lineOpacity: 1,
})
