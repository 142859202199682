import React from 'react'
import { Timeline, ChecklistTimelineItem } from '@common/Timeline'
import { useChecklistTimeline } from '@core/react-query/features/checklists/checklist'

type ChecklistTimelineTabProps = {
  checklistId: number
}

export const TimelineTab: React.FC<ChecklistTimelineTabProps> = ({
  checklistId,
}) => {
  const {
    data: timelineData,
    isFetching: checklistTimelineLoading,
    error: checklistTimelineError,
  } = useChecklistTimeline({ checklistId })

  return (
    <>
      <ErrorMessage>{checklistTimelineError}</ErrorMessage>
      <Timeline loading={checklistTimelineLoading}>
        {timelineData?.map((timelineItem, index) => (
          <ChecklistTimelineItem
            key={index}
            timelineItem={timelineItem}
            checklistId={checklistId}
          />
        ))}
      </Timeline>
    </>
  )
}
