import { ITemplateQuestionGroup } from '@core/entities/template/TemplateQuestionGroup'
import { s3Upload } from '@core/react-query/helpers'

export const uploadProofs: (
  proofs: any,
  folderName: string,
) => Array<Promise<any>> = (proofs, folderName) => {
  return proofs.map(async (proof: any) => {
    if (!proof.downloadUrl) {
      const uploadedFileKey = await s3Upload({
        file: proof,
        folderName,
      })
      return { ...proof, key: uploadedFileKey }
    }
    return proof
  })
}

const cleanupUploadedAnswerFiles: (
  answers: Array<ITemplateQuestionGroup>,
) => Array<ITemplateQuestionGroup> = (answers) => {
  const cleanedAnswers: Array<ITemplateQuestionGroup> = []
  for (const group of answers) {
    const cleanedGroupData = group.data.map((question) => {
      const cleanedQuestion = { ...question }
      if (question.localSignature) {
        // @ts-ignore
        API.removeFile?.(question.localSignature.uri)
        delete cleanedQuestion.localSignature
      }
      return cleanedQuestion
    })
    cleanedAnswers.push({ ...group, data: cleanedGroupData })
  }

  return cleanedAnswers
}

export const uploadTemplateAnswerFiles: (args: {
  answers: Array<ITemplateQuestionGroup>
  templateId: number
}) => Promise<Array<ITemplateQuestionGroup>> = async ({
  answers,
  templateId,
}) => {
  if (!answers) return
  const uploadedAnswers: Array<ITemplateQuestionGroup> = []
  for (const group of answers) {
    const questionGroupWithUploads = group.data.map(async (question) => {
      const uploadedQuestion = { ...question, signatureKey: undefined }

      if (question?.signatureUrl) {
        uploadedQuestion.signatureKey = question.signatureKey
      }

      if (question.localSignature) {
        const uploadedFileKey = await s3Upload({
          file: question.localSignature,
          folderName: `${templateId}-signature`,
        })
        uploadedQuestion.signatureKey = uploadedFileKey
      }

      if (question.submissionProofs) {
        const proofUploads = uploadProofs(
          question.submissionProofs,
          `${templateId}-proof`,
        )
        const uploadedProofs = await Promise.all(proofUploads)
        uploadedQuestion.submissionProofs = uploadedProofs
      }

      if (question.submissionDocuments) {
        const proofUploads = uploadProofs(
          uploadedQuestion.submissionDocuments,
          `${templateId}-documents`,
        )
        const uploadedProofs = await Promise.all(proofUploads)
        uploadedQuestion.submissionDocuments = uploadedProofs
      }

      if (question.acknowledgeInformationDocument) {
        const proofUploads = uploadProofs(
          [uploadedQuestion.acknowledgeInformationDocument],
          `${templateId}-documents`,
        )
        const [uploadedProof] = await Promise.all(proofUploads)
        uploadedQuestion.acknowledgeInformationDocumentKey = uploadedProof.key
        delete question.acknowledgeInformationDocument
      }

      return uploadedQuestion
    })
    const uploadedGroupData = await Promise.all(questionGroupWithUploads)
    uploadedAnswers.push({ ...group, data: uploadedGroupData })
  }

  const cleanedAnswers = cleanupUploadedAnswerFiles(uploadedAnswers)

  return cleanedAnswers
}
