import { localizedStrings } from '@core/strings'
import React from 'react'
import { PageUrls } from '@core/page-urls'
import { useRouter } from 'next/router'
import { Box, Text, Flex } from '@fundamentals'
import { Button } from '@common/material'
import { UserAvatar } from '@common/UserAvatar'
import { useUnimpersonateUser } from '@core/react-query/features/support/hooks/useUnimpersonateUser'
import { useGetProfile } from '@core/react-query/features/profile/hooks/useGetProfile'

type NavbarProps = {
  variant: 'dashboard' | 'default'
}

const Profile: React.FC<NavbarProps> = ({ variant }) => {
  const { data: profile } = useGetProfile()
  const unimpersonateMutation = useUnimpersonateUser()
  const router = useRouter()

  const handleUnimpersonateUser = async () => {
    await unimpersonateMutation.mutateAsync({
      unimpersonatedUserId: profile.id,
    })
    setTimeout(() => {
      router.push('/')
    }, 500)
  }

  return (
    profile && (
      <Box mr={2}>
        <Box
          padding={1}
          onClick={() =>
            router.push(
              PageUrls.profile({
                organisationId: profile?.organisations?.[0]?.id,
              }),
            )
          }
          sx={{ cursor: 'pointer' }}
        >
          <Flex alignItems='center'>
            {profile.impersonated ? (
              <div>
                <Button
                  variant='contained'
                  size='medium'
                  sx={{ mr: 2 }}
                  data-test='impersonate-user-button'
                  onClick={handleUnimpersonateUser}
                >
                  {localizedStrings.unimpersonateUser}
                </Button>
              </div>
            ) : null}
            <Text
              mr={2}
              alignSelf='center'
              color={variant === 'dashboard' ? 'white' : 'inherit'}
            >
              {profile?.firstName} {profile?.lastName}
              <Text
                alignSelf='center'
                fontSize={15}
                color={variant === 'dashboard' ? 'info' : 'primary'}
              >
                {profile.impersonated ? 'Impersonated' : null}
              </Text>
            </Text>
            <UserAvatar user={profile} />
          </Flex>
        </Box>
      </Box>
    )
  )
}

export { Profile }
