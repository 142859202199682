import { ModalDialogContainer } from '@common/modal'
import { useModal } from '@common/GlobalModal'
import { DialogContent, DialogTitle, IconButton } from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import Editor from '@monaco-editor/react'
import React from 'react'

export const ObjectDetectionDebugModal = (response: any) => {
  const { hideModal } = useModal()

  return (
    <ModalDialogContainer>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Editor
          width='40vw'
          height='90vh'
          defaultLanguage='json'
          defaultValue={JSON.stringify(response, null, 2)}
        />
      </DialogContent>
    </ModalDialogContainer>
  )
}
