import { localizedStrings } from '@core/strings'
import React, { FC, useState } from 'react'
import { Collapse } from '@mui/material'
import { Box, Flex, Text } from '@fundamentals'
import { Button } from '@common/material'
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@mui/icons-material'
import { Image } from '@fundamentals'
import { useDeepCompareMemo } from 'use-deep-compare'
import { useModal } from '@common/GlobalModal'
import { Marker } from 'react-map-gl'
import turf from 'turf'
import EntityDetailsMap from '@modules/maps/EntityDetailsMap'
import EntityMapModal from '@modules/maps/EntityMapModal'
import { useNavigationContext } from '@common/hooks'
import { TimelineItemBase } from '@common/Timeline/TimelineItemBase'

import { Permit } from '@core/entities/permit/Permit'
import { AuditResult } from '@core/entities/audit/AuditResult'
import { WarningDetails } from '@common/Timeline/WarningDetails'
import { AttachmentInput } from '@elements/Questions/AttachmentInput'

export type PermitTimelineItemProps = {
  timelineItem: AuditResult
  permit: Permit
}

export const PermitTimelineItem: FC<PermitTimelineItemProps> = ({
  timelineItem,
  permit,
}) => {
  const { info, signatureUrl, metadata } = timelineItem
  const timelineCoordinates = timelineItem.getCoordinates()
  const auditPointAccuracyRadius = timelineItem?.getAuditPointAccuracyRadius()

  const { showModal } = useModal()
  const [expanded, setExpanded] = useState(false)
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const locationMarkers = useDeepCompareMemo(() => {
    const markers = []
    if (timelineCoordinates) {
      const [longitude, latitude] = timelineCoordinates.geometry.coordinates
      markers.push(
        <Marker
          // VERY IMPORTANT: this makes the bottom point of the marker be exactly at the coordinates
          anchor='bottom'
          latitude={latitude}
          longitude={longitude}
        >
          <Image
            alt='User marker'
            src='/images/icons/markers/user_marker_blue.svg'
          />
        </Marker>,
      )
    }
    return markers
  }, [timelineCoordinates])

  const auditEventPoint = timelineCoordinates
    ? turf.point(timelineCoordinates.geometry.coordinates)
    : null

  const { organisationId } = useNavigationContext()

  const handleMapClick = () => {
    showModal(
      <EntityMapModal
        projectId={permit.project.id}
        entityPoint={permit.getW3wLocation()}
        geoLocationAreas={permit?.getGeolocationAreas()}
        organisationId={organisationId}
        auditPointAccuracyRadius={auditPointAccuracyRadius}
        initialFeatures={auditEventPoint ? [auditEventPoint] : null}
      >
        {locationMarkers}
      </EntityMapModal>,
    )
  }

  return (
    <TimelineItemBase timelineItem={timelineItem}>
      {metadata?.transferReceiver && (
        <Text fontSize={12}>
          {`${localizedStrings.intendedRecipient}: ${metadata.transferReceiver.firstName} ${metadata.transferReceiver.lastName}`}
        </Text>
      )}

      {metadata?.permitRoleName && (
        <Text fontWeight='bold' fontSize={12}>
          {metadata?.permitRoleName}
        </Text>
      )}
      {(info ||
        signatureUrl ||
        timelineCoordinates ||
        metadata.warnings?.length > 0 ||
        metadata.mediaUrls.length > 0) &&
        !expanded && (
          <Box>
            <Button
              variant='text'
              size='medium'
              endIcon={<ExpandMoreIcon />}
              onClick={handleExpandClick}
              sx={{ mt: 2, ml: '-8px', pb: 0, opacity: expanded ? 0 : 1 }}
            >
              {localizedStrings.showInfo}
            </Button>
          </Box>
        )}
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        {timelineItem.metadata.distanceToPermitInMeters && (
          <Text mt={1}>{`${localizedStrings.distanceToPermit}: ${Math.round(
            timelineItem.metadata.distanceToPermitInMeters,
          )} m`}</Text>
        )}

        {auditEventPoint && (
          <EntityDetailsMap
            projectId={permit.project.id}
            entityPoint={permit.getW3wLocation()}
            geoLocationAreas={permit?.getGeolocationAreas()}
            onMapClick={handleMapClick}
            initialFeatures={[auditEventPoint]}
            auditPointAccuracyRadius={auditPointAccuracyRadius}
            style={{ height: '600px' }}
          >
            {locationMarkers}
          </EntityDetailsMap>
        )}

        {timelineItem.metadata.mediaUrlsWithType?.length > 0 && (
          <>
            <Text
              sx={{ fontSize: 12, mt: 2 }}
              color='text.secondary'
              gutterBottom
            >
              {localizedStrings.proofs}
            </Text>
            <Flex flexWrap='wrap' my={2}>
              <AttachmentInput
                size='small'
                inputProps={{
                  value: timelineItem.metadata.mediaUrlsWithType,
                }}
                readOnly
                borderLess
              />
            </Flex>
          </>
        )}

        {signatureUrl && (
          <>
            <Text
              sx={{ fontSize: 12, mt: 2 }}
              color='text.secondary'
              gutterBottom
            >
              {localizedStrings.signature}
            </Text>
            <Image mt={2} src={signatureUrl} width={170} />
          </>
        )}

        {!!metadata?.warnings?.length &&
          metadata.warnings.map((warning) => (
            <WarningDetails
              key={warning.id}
              warning={warning}
              permit={permit}
            />
          ))}

        <Box>
          <Button
            variant='text'
            size='medium'
            endIcon={<ExpandLessIcon />}
            onClick={handleExpandClick}
            sx={{ mt: 2, ml: '-8px', pb: 0, opacity: !expanded ? 0 : 1 }}
          >
            {localizedStrings.hideAll}
          </Button>
        </Box>
      </Collapse>
    </TimelineItemBase>
  )
}
