import React from 'react'
import { DialogContent } from '@common/material'
import ChecklistDetails from '@modules/checklistDetails/ChecklistDetails'
import { ModalDialogContainer } from '@common/modal'

type ChecklistDetailsModalProps = {
  checklistId: number
  organisationId: number
}

const ChecklistDetailsModal: React.FC<ChecklistDetailsModalProps> = ({
  checklistId,
  organisationId,
}) => {
  return (
    <ModalDialogContainer
      PaperProps={{
        sx: {
          maxWidth: '80%',
        },
      }}
    >
      <DialogContent
        sx={{
          overflow: 'auto',
          height: '100vh',
          flexDirection: 'column',
          my: '20px',
          py: 0,
        }}
      >
        <ChecklistDetails
          checklistId={checklistId}
          organisationId={organisationId}
          isModal
        />
      </DialogContent>
    </ModalDialogContainer>
  )
}

export default ChecklistDetailsModal
