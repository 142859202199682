import React from 'react'
import { CardProps, TablePagination as Pagination } from '@mui/material'
import { Card } from '@common/material'

type ComponentType = {
  page: number
  size: number
  count: number
  withoutCard?: boolean
  cardSx?: CardProps['sx']
  onChange: (pagination: { size: number; page: number }) => void
}

export const TablePagination: React.FC<ComponentType> = ({
  page,
  size,
  count = 100,
  withoutCard,
  cardSx,
  onChange = () => {},
}) => {
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    // We add 1 because pagination on the back-end is indexed from 1
    // However, the MUI component wants it to be indexed from 0
    onChange({ size, page: newPage + 1 })
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onChange({ size: parseInt(event.target.value, 10), page: 0 })
  }

  return (
    <Card
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        borderLeft: 0,
        borderRight: 0,
        borderTop: 0,
        borderBottom: withoutCard && 0,
        '& p': {
          mb: 0,
        },
        ...cardSx,
      }}
    >
      <Pagination
        component='div'
        count={count}
        // We remove 1 because pagination on the back-end is indexed from 1
        // However, the MUI component wants it to be indexed from 0
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={size}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  )
}
