import { AuditAction } from '@core/entities/audit/AuditResult/types'
import { PermitSuspensionReasons } from '@core/entities/permit/PermitSuspension'
import { IUser } from '@core/entities/user'
import { RegisterItemWithLinksPropertyData } from '@core/entities/register/RegisterItem'
import { GeoJSON } from 'geojson'
import { CommentSecondaryReferenceObjectTypes } from '@core/entities/comments'
import { ITemplateQuestion } from '@core/entities/template/TemplateQuestion'
import { getMediaUrlContentType } from '@core/react-query/helpers/mediaHelpers'
import { IPermitWarning } from '@core/entities/template/TemplateWarnings'

export const attachAuditResultMediaTypes = async (audit: IAuditResult) => {
  if (!audit.metadata?.mediaUrls) return
  await Promise.all(
    audit.metadata?.mediaUrls.map(async (mediaUrl) => {
      if (!audit.metadata.mediaUrlsWithType) {
        audit.metadata.mediaUrlsWithType = []
      }
      const mediaType = await getMediaUrlContentType(mediaUrl)
      audit.metadata.mediaUrlsWithType.push({
        getUrl: mediaUrl,
        type: mediaType,
      })
    }),
  )
}

export interface IAuditResultMetadata {
  mediaUrls?: Array<string>
  mediaUrlsWithType?: Array<{ getUrl: string; type: string }>
  warnings?: Array<IPermitWarning>
  permitRoleName?: string
  checklistRoleName?: string
  permitGeoJson?: GeoJSON
  distanceToPermitInMeters?: number
  transferReceiver?: IUser
  taskChecklistId?: number
  suspensionReasonCategory?: PermitSuspensionReasons
  delta: {
    itemValues: RegisterItemWithLinksPropertyData[]
  }
  postValues: {
    itemValues: RegisterItemWithLinksPropertyData[]
  }
  preValues: {
    itemValues: RegisterItemWithLinksPropertyData[]
  }
  updateNote?: string
  inputType?: string
  commentSecondaryReferenceObjectId: number
  commentSecondaryReferenceObjectIdText: string
  commentSecondaryReferenceObjectType: CommentSecondaryReferenceObjectTypes
  commentAnsweredQuestionDataResult: ITemplateQuestion
  emailAddress?: string
}

export interface IAuditResult {
  id: number
  createdOn: string
  platform: 'WEB' | 'ANDROID' | 'IOS' | 'UNKNOWN'
  action: AuditAction
  signatureUrl?: string
  info?: string
  user?: IUser
  organisationUser?: IUser
  projectUser?: IUser
  metadata?: IAuditResultMetadata
  geoJSON?: GeoJSON
  coordinates?: {
    latitude: number
    longitude: number
    locationAccuracyInMeters?: number
    locationAccuracyStatus?: string
  }
  project?: {
    id: number
    name: string
  }
  organisation?: {
    id: number
    name: string
  }
  permit?: {
    id: number
  }
  checklist?: {
    id: number
  }

  register?: {
    id: number
    name: string
  }

  registerItem?: {
    id: number
    uniqueId: string
  }
}
