import React from 'react'
import { Box } from '@fundamentals'
import { PermitQuestionsTabProps } from '../types'
import { RenderAnswers } from '@elements/Questions/RenderAnswers'
import WarningCard from '@modules/permitDetails/WarningCard'
import useFeatureFlags from '@core/providers/useFeatureFlags'

export const PermitTab: React.FC<PermitQuestionsTabProps> = ({ permit }) => {
  const warnings = permit?.warnings || []
  const clonedPermitWarning = warnings.find((w) => w.type === 'CLONED_PERMIT')
  const { flags } = useFeatureFlags(['permit_warnings'])

  return (
    <Box flex={1}>
      {flags.permit_warnings && clonedPermitWarning && (
        <WarningCard warning={clonedPermitWarning} />
      )}
      <RenderAnswers
        questionBuilderAnswers={permit?.authorisedPersonAnswers}
        permitId={permit?.id}
        organisationId={permit?.template?.organisationId}
        warnings={warnings}
      />
      {permit?.approvalReviews?.approvals
        .filter((a) => a.approval.approvalStage === 'BEFORE_PERMIT_HOLDER')
        .map(({ approval }) => (
          <RenderAnswers
            key={approval.id}
            questionBuilderAnswers={approval?.approvalAnswers}
            organisationId={permit?.template?.organisationId}
            warnings={warnings}
          />
        ))}
      <RenderAnswers
        questionBuilderAnswers={permit?.permitHolderAnswers}
        permitId={permit?.id}
        organisationId={permit?.template?.organisationId}
        warnings={warnings}
      />
      {permit?.approvalReviews?.approvals
        .filter((a) => a.approval.approvalStage === 'AFTER_PERMIT_HOLDER')
        .map(({ approval }) => (
          <RenderAnswers
            key={approval.id}
            questionBuilderAnswers={approval?.approvalAnswers}
            organisationId={permit?.template?.organisationId}
            warnings={warnings}
          />
        ))}
      {permit?.finalSignOffReviews?.reviews?.map(({ finalSignOff }) => (
        <RenderAnswers
          key={finalSignOff.id}
          questionBuilderAnswers={finalSignOff?.finalSignOffAnswers}
          organisationId={permit?.template?.organisationId}
          warnings={warnings}
        />
      ))}
    </Box>
  )
}
