import { PageUrls } from '@core/page-urls'
import React from 'react'
import { Button } from '@common/material'
import { localizedStrings } from '@core/strings'
import { Box } from '@fundamentals'
import { PermitActionCard } from '../PermitActionCard'
import { useRouter } from 'next/router'
import { Permit } from '@core/entities/permit/Permit'

type AddressRequestedChangesCardProps = {
  organisationId: number
  projectId: number
  permit: Permit
}
export const AddressRequestedChangesCard: React.FC<
  AddressRequestedChangesCardProps
> = ({ organisationId, projectId, permit }) => {
  const router = useRouter()
  if (!permit.hasPermission('ADDRESS_REQUESTED_CHANGES')) return null

  const { requestedAt, requestedBy } = permit.lastRequestedChange
  const { firstName, lastName } = requestedBy
  const { dateString, timeString } = Utils.getDate(requestedAt)

  return (
    <PermitActionCard
      title={localizedStrings.changesRequested}
      description={permit.lastRequestedChange.comment}
      info={
        <Box>
          <Box>{`${localizedStrings.requestedBy}: ${firstName} ${lastName}`}</Box>
          <Box>{`${localizedStrings.date}: ${dateString} ${timeString}`}</Box>
        </Box>
      }
      BottomComponent={
        <Button
          data-test='respond-to-changes'
          variant='contained'
          fullWidth
          onClick={() => {
            if (projectId) {
              router.push(
                PageUrls.addressRequestedProjectPermitChanges({
                  organisationId,
                  projectId,
                  permitId: permit.id,
                }),
              )
            } else {
              router.push(
                PageUrls.addressRequestedPermitChanges({
                  organisationId: organisationId,
                  permitId: permit.id,
                }),
              )
            }
          }}
        >
          {localizedStrings.addressChanges}
        </Button>
      }
      sx={{ mb: 1.5, borderColor: 'primary.main', borderWidth: 2 }}
    />
  )
}
