import { localizedStrings } from '@core/strings'
import React from 'react'
import { Text, Box, Flex } from 'webapp/components/common/fundamentals'
import { Card, Divider } from '@common/material'
import { CardContent, Typography } from '@mui/material'
import moment from 'moment'
import { Checklist } from '@core/entities/checklist/Checklist'
import { useModal } from '@common/GlobalModal'
import { PageUrls } from '@core/page-urls'
import { Section } from '@common/Section'
import EntityMapModal from '@modules/maps/EntityMapModal'
import ChevronRight from '@mui/icons-material/ChevronRight'
import { ParticipantLogChecklistModal } from '@modules/checklistDetails/modals/ParticipantLogChecklistModal'

type ChecklistDetailsSidebarProps = {
  checklist: Checklist
  organisationId: number
  isModal?: boolean
}

export const ChecklistDetailsSidebar: React.FC<
  ChecklistDetailsSidebarProps
> = ({ checklist, organisationId, isModal }) => {
  const { showModal } = useModal()

  const handlePersonClick = (participant) => {
    showModal(
      <ParticipantLogChecklistModal
        participantDetail={participant}
        checklist={checklist}
      />,
    )
  }

  return (
    <Box>
      <Card>
        <CardContent>
          <Typography
            sx={{ fontSize: '16px' }}
            variant='h6'
            color='text.primary'
            gutterBottom
          >
            General Information
          </Typography>
          <Divider />

          <Section label={localizedStrings.summary} mt={2}>
            <Text data-test='permit-summary' showTooltip>
              {checklist.summary}
            </Text>
          </Section>

          <Section label={localizedStrings.project} mt={2}>
            <Text showTooltip>{checklist.project.name}</Text>
          </Section>

          <Section label={localizedStrings.location} mt={2}>
            <Text showTooltip>{checklist.locationName}</Text>
          </Section>

          <Section label={localizedStrings.uniqueCode} mt={2}>
            <Text showTooltip>{checklist.shortUUID}</Text>
          </Section>

          {checklist.getCheckinLocation() && (
            <Section label={localizedStrings.geoLocation} mt={2}>
              <Text
                color='primary.main'
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  showModal(
                    <EntityMapModal
                      organisationId={organisationId}
                      projectId={checklist.project.id}
                      entityPoint={checklist.getCheckinLocation()}
                    />,
                  )
                }}
              >
                {localizedStrings.showOnMap}
              </Text>
            </Section>
          )}

          {checklist?.ownedByRegisterItem && (
            <Section label={localizedStrings.registerItem} mt={2}>
              <Text
                onClick={() => {
                  window.open(
                    PageUrls.projectRegisterItemDetails({
                      organisationId: checklist.organisationId,
                      projectId: checklist.project.id,
                      registerId: checklist.ownedByRegisterItem.register.id,
                      registerItemId:
                        checklist.ownedByRegisterItem.registerItem.id,
                    }),
                    '_blank',
                    'noopener, noreferrer',
                  )
                }}
                color='primary.main'
                sx={{ cursor: 'pointer' }}
                showTooltip
              >{`${checklist.ownedByRegisterItem.register.name} - ${checklist.ownedByRegisterItem.registerItem.uniqueId}`}</Text>
            </Section>
          )}

          <Section label={localizedStrings.date} mt={2}>
            <Text showTooltip>
              {moment(checklist.createdOn).format('Do MMM YYYY HH:mm')}
            </Text>
          </Section>
        </CardContent>
      </Card>
      <Card sx={{ marginTop: 2 }}>
        <CardContent>
          <Text
            showTooltip
            sx={{ fontSize: '16px' }}
            variant='h6'
            color='text.primary'
            gutterBottom
          >
            {localizedStrings.people}
          </Text>
          <Divider />

          {checklist?.createdBy && (
            <Section label={localizedStrings.checklistAuthor} mt={2}>
              <Flex justifyContent='space-between'>
                <Text showTooltip>{checklist.createdBy.getFullName()}</Text>
              </Flex>
            </Section>
          )}

          {!!checklist?.participants?.length && (
            <>
              <Text
                mt={4}
                sx={{ fontSize: '16px' }}
                variant='h6'
                color='text.primary'
                gutterBottom
                showTooltip
              >
                {localizedStrings.participants}
              </Text>
              <Divider sx={{ mb: 1 }} />

              {checklist?.participants.map((participant) => {
                return (
                  <Section
                    key={participant.id}
                    label={participant.getRole()}
                    mt={2}
                  >
                    <Flex
                      justifyContent='space-between'
                      onClick={() => handlePersonClick(participant)}
                      sx={{ cursor: 'pointer' }}
                    >
                      <Text showTooltip>{participant.getFullName()}</Text>
                      <ChevronRight />
                    </Flex>
                  </Section>
                )
              })}
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  )
}
