import React from 'react'
import { Box, Flex, Text } from '@fundamentals'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { Alert, Card } from '@common/material'
import { useTheme, Link } from '@mui/material'
import { localizedStrings } from '@core/strings'
import { useModal } from '@common/GlobalModal'
import { PermitDetailsModal } from '@modules/permitDetails/PermitDetailsModal'
import { IPermitWarning } from '@core/entities/template/TemplateWarnings/IPermitWarning'
import { useNavigationContext } from '@common/hooks'
import { DuplicatedImagePermitModal } from '@modules/permitDetails/modals/DuplicatedImagePermitModal'

type ComponentProps = {
  warning: IPermitWarning
}

const WarningCard: React.FC<ComponentProps> = ({ warning }) => {
  const theme: any = useTheme()
  const { showModal } = useModal()
  const { organisationId } = useNavigationContext()

  const getTitle = () => {
    switch (warning.type) {
      case 'CLONED_PERMIT':
        return localizedStrings.clonedPermitDetected
      case 'IMAGE_DUPLICATE':
        return localizedStrings.duplicateImagesDetected
      case 'IMAGE_OBJECT_DETECTION':
        return localizedStrings.hazardDetected
      default:
        return localizedStrings.warningDetected
    }
  }

  return (
    <Card sx={{ p: 1.5, borderColor: theme.palette.warning.main }}>
      <Flex alignItems='center'>
        <WarningAmberOutlinedIcon color='warning' />
        <Text variant='body2' sx={{ mx: 1, fontWeight: 500 }}>
          {getTitle()}
        </Text>
        {warning.type !== 'IMAGE_OBJECT_DETECTION' && (
          <Link
            component='button'
            variant='body2'
            onClick={() => {
              if (warning.type === 'CLONED_PERMIT') {
                showModal(
                  <PermitDetailsModal
                    organisationId={warning.details.organisationId}
                    permitId={warning.details.id}
                  />,
                )
              }

              if (warning.type === 'IMAGE_DUPLICATE') {
                showModal(
                  <DuplicatedImagePermitModal
                    organisationId={organisationId}
                    imageDuplicates={warning.details?.duplicates || []}
                  />,
                )
              }
            }}
          >
            {localizedStrings.seeDetails}
          </Link>
        )}
      </Flex>
      {warning.type === 'IMAGE_OBJECT_DETECTION' && (
        <Alert severity='warning' icon={false} sx={{ mt: 1 }}>
          <Box
            dangerouslySetInnerHTML={{ __html: warning.text }}
            color='grey.800'
            sx={{ '& p': { mb: 0, fontSize: '14px' } }}
          />
        </Alert>
      )}
    </Card>
  )
}

export default WarningCard
