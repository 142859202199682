import React from 'react'
import { Button } from '@common/material'
import { localizedStrings } from '@core/strings'
import { Box, Flex, Text } from '@fundamentals'
import { PermitActionCard } from '../PermitActionCard'
import { useModal } from '@common/GlobalModal'
import { Permit } from '@core/entities/permit/Permit'
import moment from 'moment'
import { PersonOutlineOutlined } from '@mui/icons-material'
import { alpha } from '@mui/material/styles'
import { RejectPermitTransferModal } from '../modals/RejectPermitTransferModal'
import { AcceptPermitTransferModal } from '../modals/AcceptPermitTransferModal'
import { DeclinePermitTransferModal } from '../modals/DeclinePermitTransferModal'
import { ApprovePermitTransferModal } from '../modals/ApprovePermitTransferModal'
import { CancelPermitTransferModal } from '../modals/CancelPermitTransferModal'

type TransferPermitCardProps = {
  permit: Permit
}
export const TransferPermitCard: React.FC<TransferPermitCardProps> = ({
  permit,
}) => {
  const { showModal } = useModal()
  if (!permit?.lastTransfer?.isPending()) return null

  const { message, createdOn, sentTo, createdBy } = permit.lastTransfer
  const datetimeString = moment(createdOn).format('D MMMM YYYY HH:mm')

  return (
    <PermitActionCard
      title={localizedStrings.transferAwaitingRecipient}
      recipientSection={
        <>
          <PersonOutlineOutlined sx={{ color: alpha('#000', 0.54) }} />
          <Text sx={{ fontSize: '12px', fontWeight: 500, ml: 1 }}>
            {`${localizedStrings.recipient}: ${sentTo.getFullName()}`}
          </Text>
        </>
      }
      description={message}
      info={
        <Box>
          <Box>{`${
            localizedStrings.requestedBy
          }: ${createdBy.getFullName()}`}</Box>
          <Box>{`${localizedStrings.date}: ${datetimeString}`}</Box>
        </Box>
      }
      BottomComponent={
        <>
          {permit.hasPermission('ACCEPT_TRANSFER') && (
            <Flex gap={1}>
              <Button
                data-test='reject-permit-transfer'
                variant='outlined'
                color='error'
                fullWidth
                onClick={() =>
                  showModal(<RejectPermitTransferModal permit={permit} />)
                }
              >
                {localizedStrings.reject}
              </Button>
              <Button
                data-test='accept-permit-transfer'
                variant='contained'
                fullWidth
                onClick={() =>
                  showModal(<AcceptPermitTransferModal permit={permit} />)
                }
              >
                {localizedStrings.accept}
              </Button>
            </Flex>
          )}
          {permit.hasPermission('REVIEW_TRANSFER') && (
            <Flex gap={1}>
              <Button
                data-test='decline-permit-transfer'
                variant='outlined'
                color='error'
                fullWidth
                onClick={() =>
                  showModal(<DeclinePermitTransferModal permit={permit} />)
                }
              >
                {localizedStrings.reject}
              </Button>
              <Button
                data-test='approve-permit-transfer'
                variant='contained'
                fullWidth
                onClick={() =>
                  showModal(<ApprovePermitTransferModal permit={permit} />)
                }
              >
                {localizedStrings.approve}
              </Button>
            </Flex>
          )}
          {permit.hasPermission('CANCEL_TRANSFER') && (
            <Button
              data-test='cancel-permit-transfer'
              variant='outlined'
              fullWidth
              onClick={() =>
                showModal(<CancelPermitTransferModal permit={permit} />)
              }
            >
              {localizedStrings.cancel}
            </Button>
          )}
        </>
      }
      sx={{ mb: 1.5, borderColor: 'primary.main', borderWidth: 2 }}
    />
  )
}
