import React, { MutableRefObject, useRef } from 'react'
import { Feature, Point, Polygon } from 'geojson'
import { MarkerProps } from 'react-map-gl/dist/esm/components/marker'
import { MapBoxMap, MapProps as BaseMapProps } from '@common/material/MapBox'
import { Layer, MapboxEvent, MapRef, Marker, Source } from 'react-map-gl'
import {
  existingZoneExtrusionStyleWeb,
  existingZoneFillStyleWeb,
  existingZoneLineStyleWeb,
} from '@core/utils/map-styling'
import { zoomToFeatures } from '@modules/maps/utils'
import _ from 'lodash'

type MapProps = BaseMapProps & {
  features?: Array<
    Partial<Omit<MarkerProps, 'longitude' | 'latitude'>> & {
      id: string | number
      feature: Feature<Point>
      icon?: React.ReactNode
    }
  >
  geoZones?: Array<{
    zone: Feature<Polygon>
    id: string | number
  }>
  hoveredState?: {
    entityId: number
    source: 'list' | 'map'
  }
  mapRef: MutableRefObject<MapRef>
}

const Map: React.FC<MapProps> = ({
  onLoad,
  features,
  geoZones,
  hoveredState,
  mapRef: _mapRef,
  ...props
}) => {
  const mapRef = _mapRef || useRef<MapRef>()

  const _onLoad = (e: MapboxEvent) => {
    if (features?.length) {
      zoomToFeatures({ mapRef, features: _.map(features, 'feature') })
    }
    onLoad?.(e)
  }

  return (
    <MapBoxMap mapRef={mapRef} onLoad={_onLoad} {...props}>
      {features?.map(({ feature, icon, id, ...featureProps }, index) => {
        const [longitude, latitude] = feature.geometry.coordinates

        return (
          <Marker
            key={id}
            // VERY IMPORTANT: this makes the bottom point of the marker be exactly at the coordinates
            anchor='bottom'
            longitude={longitude}
            latitude={latitude}
            style={{ zIndex: hoveredState?.entityId === id ? 10 : 1 }}
            {...featureProps}
          >
            {icon}
          </Marker>
        )
      })}
      {geoZones?.map(({ zone, id, ...zoneProps }, index) => {
        const hovered = hoveredState?.entityId === id
        const fillStyle = existingZoneFillStyleWeb(hovered)
        const lineStyle = existingZoneLineStyleWeb(hovered)

        return (
          <Source
            key={`areas-${id}-${index}`}
            id={`areas-${id}`}
            type='geojson'
            data={zone}
          >
            <Layer id={`areas-${id}`} type={'fill'} paint={fillStyle} />
            {hovered && (
              <Layer
                id={`extrusion-${id}`}
                beforeId={`areas-${id}`}
                type={'fill-extrusion'}
                paint={existingZoneExtrusionStyleWeb()}
              />
            )}
            <Layer id={`areas-line-${id}`} type={'line'} paint={lineStyle} />
          </Source>
        )
      })}
    </MapBoxMap>
  )
}

export { Map }
