import { localizedStrings } from '@core/strings'
import React, { FunctionComponent, useMemo, useRef, useState } from 'react'
import { Box, Flex, Text } from '@fundamentals'
import { MenuItem, TextField } from '@common/material'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { useGetProjectLocations } from '@core/react-query/features/projects/locations'
import { TemplateProjectLocations } from '@core/entities/template/TemplateProjectLocations'
import { ClickAwayListener } from '@mui/base'
import { TextFieldProps } from '@mui/material'
import { Section } from '@common/Section'

type ProjectLocationPickerProps = TextFieldProps & {
  projectId: number
  value: TemplateProjectLocations['locations']
  onChange?: (locations: TemplateProjectLocations['locations']) => void
  readOnly?: boolean
}

export const ProjectLocationPicker: FunctionComponent<
  ProjectLocationPickerProps
> = ({ projectId, value, onChange, readOnly, error, ...rest }) => {
  const locationName = value?.[0]?.locationName // ?? value?.localLocationName
  const locationNodeId = value?.[0]?.locationNodeId ?? ''

  const searchInputRef = useRef(null)
  const [locationStack, setLocationStack] = useState([])
  const [locationSearchTerm, setLocationSearchTerm] = useState('')
  const [selectedLocationName, setSelectedLocationName] =
    useState<string>(locationName)
  const [open, setOpen] = useState(false)

  const { data: projectLocations } = useGetProjectLocations(
    {
      projectId,
      statuses: ['ACTIVE'],
    },
    {
      onSuccess(projectLocations) {
        setLocationStack([projectLocations?.locations])
      },
    },
  )
  const projectLocationEnabled = projectLocations?.enabled

  const memoisedFilteredLocations = useMemo(
    () =>
      locationStack[locationStack.length - 1]?.filter((location) =>
        location.name?.toLowerCase().includes(locationSearchTerm.toLowerCase()),
      ),
    [locationSearchTerm, locationStack[locationStack.length - 1]],
  )

  const stopImmediatePropagation = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
  }

  const handleBack = (e) => {
    setLocationStack(locationStack.slice(0, -1))
    setLocationSearchTerm('')
    setSelectedLocationName(
      selectedLocationName.split(' / ').slice(0, -1).join(' / '),
    )
    searchInputRef.current?.focus()
    e.stopPropagation()
  }

  const handleLocationClick = (e, location, index) => {
    // Focus search input on location click
    searchInputRef.current?.focus()

    // Handle location with children selection
    setSelectedLocationName((prevLocatonName) =>
      locationStack.length > 1
        ? `${prevLocatonName} / ${memoisedFilteredLocations[index].name}`
        : memoisedFilteredLocations[index].name,
    )
    setLocationStack([
      ...locationStack,
      memoisedFilteredLocations[index].locations,
    ])
    setLocationSearchTerm('')

    // Handle location without children selection
    if (!memoisedFilteredLocations[index].locations.length) {
      onChange([
        {
          locationNodeId: location.id,
          locationName: selectedLocationName
            ? `${selectedLocationName} / ${memoisedFilteredLocations[index].name}`
            : memoisedFilteredLocations[index].name,
          locationPlanId:
            location?.planStatus === 'PUBLISHED' ? location?.planId : null,
        },
      ])
      setOpen(false)
    }
    e.stopPropagation()
  }

  const resetPickerState = () => {
    setLocationStack([locationStack[0]])
    setSelectedLocationName('')
    setLocationSearchTerm('')
    onChange([{ locationNodeId: '', locationName: null, locationPlanId: null }])
  }

  if (!projectLocationEnabled) return null

  if (readOnly) {
    return (
      <Section sx={rest?.sx} label={localizedStrings.location}>
        <Text>{selectedLocationName}</Text>
      </Section>
    )
  }

  return (
    <TextField
      data-test='select-location'
      label={localizedStrings.location}
      placeholder='Enter Location'
      required
      select
      disabled={readOnly}
      value={locationNodeId}
      onClick={() => {
        if (!readOnly) {
          setOpen(true)
          locationNodeId && resetPickerState()
        }
      }}
      SelectProps={{
        displayEmpty: true,
        renderValue: () => selectedLocationName,
        open: open,
        onClose: () => !locationNodeId && resetPickerState(),
      }}
      FormHelperTextProps={{ error, sx: { pt: 0.5, pb: 2 } }}
      error={error}
      fullWidth
      {...rest}
    >
      {!!locationStack.length && (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Box>
            {locationStack.length > 1 && (
              <MenuItem onClick={handleBack} sx={{ pl: 1 }}>
                <Flex width='100%'>
                  <ChevronLeft />
                  <Text pl={1}>{selectedLocationName}</Text>
                </Flex>
              </MenuItem>
            )}
            <MenuItem
              onClickCapture={stopImmediatePropagation}
              onKeyDown={(e) => e.stopPropagation()}
              disableRipple
              sx={{
                '&.Mui-focusVisible, &:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <TextField
                autoFocus
                inputRef={searchInputRef}
                label={localizedStrings.search}
                fullWidth
                size='small'
                InputLabelProps={{ shrink: true }}
                value={locationSearchTerm}
                onChange={(e) => setLocationSearchTerm(e.target.value)}
              />
            </MenuItem>

            {memoisedFilteredLocations.length ? (
              memoisedFilteredLocations.map((location, index) => (
                <MenuItem
                  key={location.id}
                  value={location.id}
                  data-test={location.id}
                  onClick={(e) => handleLocationClick(e, location, index)}
                >
                  <Flex justifyContent='space-between' width='100%'>
                    <Text>{location.name}</Text>
                    {!!memoisedFilteredLocations[index].locations.length && (
                      <ChevronRight />
                    )}
                  </Flex>
                </MenuItem>
              ))
            ) : (
              <Text sx={{ px: 2, py: 0.75 }}>
                {localizedStrings.noResultsFoundPleaseChangeSearch}
              </Text>
            )}
          </Box>
        </ClickAwayListener>
      )}
    </TextField>
  )
}
