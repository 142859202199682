import { localizedStrings } from '@core/strings'
import React from 'react'
import { Card } from '@common/material'
import { TimelineItemBase } from '@common/Timeline/TimelineItemBase'
import { Text } from '@fundamentals'
import { AuditResult } from '@core/entities/audit/AuditResult'

export const TimelineCard: React.FC<{
  timelineEntry: AuditResult
  onMouseOver?: () => void
  onMouseLeave?: () => void
  onClick?: () => void
  hovered?: boolean
  transparent?: boolean
}> = ({
  timelineEntry,
  onMouseOver,
  onMouseLeave,
  onClick,
  hovered,
  transparent,
}) => {
  if (!timelineEntry) {
    return null
  }

  return (
    <Card
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        borderColor: hovered && 'primary.main',
        p: 2,
        mb: 1,
        mr: 2,
        backgroundColor: transparent ? 'rgba(255,255,255,0.7)' : null,
      }}
    >
      <TimelineItemBase timelineItem={timelineEntry}>
        {timelineEntry.metadata.distanceToPermitInMeters && (
          <Text mt={1}>{`${localizedStrings.distanceToPermit}: ${Math.round(
            timelineEntry.metadata.distanceToPermitInMeters,
          )} m`}</Text>
        )}
      </TimelineItemBase>
    </Card>
  )
}
