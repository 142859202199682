import { Entity } from '@core/entities/entity'
import { plainToInstance, Type } from 'class-transformer'
import { TemplateFormAnswers } from '@core/entities/template/TemplateFormAnswers'
import {
  ChecklistPermittedActions,
  ChecklistStatus,
} from '@core/entities/checklist/Checklist/types'
import { IChecklist } from '@core/entities/checklist/Checklist/IChecklist'
import { localizedStrings } from '@core/strings'
import { TemplateProjectLocations } from '@core/entities/template/TemplateProjectLocations'
import { ChecklistTemplate } from '@core/entities/checklist/ChecklistTemplate'
import { User } from '@core/entities/user'
import { Participant } from '@core/entities/participant/Participant'
import { OwnedByRegisterItem } from '@core/entities/register/OwnedByRegisterItem/OwnedByRegisterItem'
import { Feature, GeoJSON, Point } from 'geojson'
import { Intent, SelfBriefingChecklistIntent } from '@core/entities/intent'
import { Comments } from '@core/entities/comments'

export class Checklist extends Entity<IChecklist> {
  id: number

  permitId?: number

  organisationId: number

  project: {
    id: number
    name: string
    timezone: string
  }

  uuid: string

  shortUUID: string

  createdOn: string

  modifiedOn: string

  @Type(() => ChecklistTemplate)
  template: ChecklistTemplate

  status: ChecklistStatus

  @Type(() => User)
  createdBy: User

  @Type(() => User)
  modifiedBy: User

  locationId: string

  locationName: string

  summary: string

  draftName?: string

  accessToken?: string

  @Type(() => TemplateFormAnswers)
  mainAnswers: TemplateFormAnswers

  @Type(() => Participant)
  participants: Array<Participant>

  @Type(() => TemplateProjectLocations)
  projectLocations: TemplateProjectLocations

  geoJSON?: GeoJSON

  permittedActions: Array<ChecklistPermittedActions>

  @Type(() => OwnedByRegisterItem)
  ownedByRegisterItem: OwnedByRegisterItem

  comments: Comments

  commentsCount?: number

  public static new(payload: unknown): Checklist {
    const entity = plainToInstance(Checklist, payload)

    return entity
  }

  public hasPermission(action: ChecklistPermittedActions): boolean {
    return this.permittedActions?.includes(action)
  }

  public getStatusChipColor() {
    return Checklist.getStatusChipColor(this.status)
  }

  public static getStatusChipColor(status: ChecklistStatus) {
    switch (status) {
      case 'COMPLETED':
        return 'primary'
      default:
        return 'success'
    }
  }

  public getStatusString(): string {
    return Checklist.getStatusString(this.status)
  }

  public getCheckinLocation(): Feature<Point> {
    if (!(this.geoJSON && this.geoJSON.type === 'FeatureCollection')) return
    const checkinGeometry = this.geoJSON.features?.[0]
    if (!checkinGeometry) return
    const { geometry, ...feature } = checkinGeometry
    if (geometry.type !== 'Point') return
    return { geometry, ...feature }
  }

  public static getStatusString(status: ChecklistStatus): string {
    switch (status) {
      case 'COMPLETED':
        return localizedStrings.completed
      default:
        return status
    }
  }

  public getBriefingIntentUrl(baseUrl: string): string {
    const intent: SelfBriefingChecklistIntent = {
      intentType: 'SELF_BRIEFING_CHECKLIST',
      checklistId: this.id,
      organisationId: this.organisationId,
      projectId: this.project.id,
      accessToken: this.accessToken,
    }

    return `${baseUrl}/intent?data=${encodeURIComponent(
      JSON.stringify(intent),
    )}`
  }

  public getOpenIntentUrl(baseUrl: string): string {
    const intent: Intent = {
      intentType: 'OPEN_CHECKLIST',
      organisationId: this.organisationId,
      projectId: this.project.id,
      checklistId: this.id,
    }
    return `${baseUrl}/intent?data=${encodeURIComponent(
      JSON.stringify(intent),
    )}`
  }

  public getImageS3Keys(): string[] {
    return this.mainAnswers.getImageS3Keys()
  }

  public getProofs() {
    return this.mainAnswers.getProofs()
  }
}
