import { Card, LinearProgress } from '@common/material'
import React from 'react'

type TimelineProps = {
  children: React.ReactNode
  loading?: boolean
}
export const Timeline: React.FC<TimelineProps> = ({ children, loading }) => {
  return (
    <Card sx={{ p: 3 }}>
      {loading && <LinearProgress />}
      {children}
      {loading && <LinearProgress />}
    </Card>
  )
}
