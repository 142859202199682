import React, { useRef } from 'react'
import { MapBoxMap, MapProps } from '@common/material/MapBox'
import useConstants from '@core/providers/useConstants'
import { MapboxEvent, MapRef, NavigationControl } from 'react-map-gl'
import { MapLayerMouseEvent } from 'react-map-gl/dist/esm/types'
import { Feature } from 'geojson'
import { zoomToFeatures } from '@modules/maps/utils'
import { useToast } from '@core/toast'
import { useProjectMapConfiguration } from '@core/react-query/features/projects/maps'

export type ProjectMapBoxMapProps = MapProps & {
  projectId: number
  initialFeatures?: Feature[]
}

const ProjectMapBase: React.FC<ProjectMapBoxMapProps> = ({
  projectId,
  onLoad: onLoadProp,
  initialFeatures,
  ...props
}) => {
  const mapRef = props.mapRef || useRef<MapRef>()
  const onClick = (e: MapLayerMouseEvent) => {
    if (props.onClick) {
      props.onClick(e)
    }
  }

  const {
    data: projectMapConfiguration,
    isFetching: projectMapConfigurationIsFetching,
  } = useProjectMapConfiguration({
    projectId,
  })

  const { constants } = useConstants()
  const toast = useToast()

  const onLoad = (e: MapboxEvent) => {
    if (initialFeatures?.length) {
      const filteredInitialFeatures = initialFeatures.filter(
        (feature) => feature !== undefined,
      )
      zoomToFeatures({ mapRef: mapRef, features: filteredInitialFeatures })
    }
    if (onLoadProp) {
      onLoadProp(e)
    }
  }

  // If a project doesn't have a mapping configuration then they will get a default map
  const defaultConfig = {
    mapBoxStyleUrl: constants.mapbox_default_style_url,
    mapCenterLatitude: 52.682,
    mapCenterLongitude: -3.114,
    mapZoom: 6,
  }

  const defaultProps = {
    initialViewState: {
      longitude:
        projectMapConfiguration?.mapCenterLongitude ||
        defaultConfig.mapCenterLongitude,
      latitude:
        projectMapConfiguration?.mapCenterLatitude ||
        defaultConfig.mapCenterLatitude,
      zoom: projectMapConfiguration?.mapZoom || defaultConfig.mapZoom,
    },
    mapStyle:
      projectMapConfiguration?.mapBoxStyleUrl || defaultConfig.mapBoxStyleUrl,
  }

  function removeEmpty(obj: any) {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null))
  }

  const propsWithProjectInfo: MapProps = {
    ...defaultProps,
    ...removeEmpty(props),
  }

  if (!projectMapConfiguration || projectMapConfigurationIsFetching) {
    return <Loader />
  }

  return (
    <>
      <MapBoxMap
        {...propsWithProjectInfo}
        mapRef={mapRef}
        onClick={onClick}
        onLoad={onLoad}
      >
        <NavigationControl />
        {propsWithProjectInfo.children}
      </MapBoxMap>
    </>
  )
}

export { ProjectMapBase }
