import { Box, Flex, Image } from '@fundamentals'
import React, { useRef, useState } from 'react'
import { useDeepCompareEffect } from 'use-deep-compare'
import { MapProps, MapRefType } from '@common/material/MapBox'
import { Marker } from 'react-map-gl'
import { DialogContent } from '@mui/material'
import { TransitionGroup } from 'react-transition-group'
import { TimelineCard } from '@elements/TimelineCar'
import EntityDetailsMap from '@modules/maps/EntityDetailsMap'
import { AuditResult } from '@core/entities/audit/AuditResult'
import { ModalDialogContainer } from '@common/modal'
import { Feature, FeatureCollection, Point, Polygon } from 'geojson'

export type ProjectMapPermitListProps = MapProps & {
  projectId: number
  entityPoint: Feature<Point>
  geoLocationAreas?: FeatureCollection<Polygon>
  timeline: Array<AuditResult>
}

const TimelineMapModal: React.FC<ProjectMapPermitListProps> = ({
  projectId,
  entityPoint,
  geoLocationAreas,
  timeline,
}) => {
  const mapRef: MapRefType = useRef()
  const [hoveredState, setHoveredState] = useState<{
    id: number
    source: 'list' | 'map'
  }>()
  const hoveredOnMap = (id: number) => {
    setHoveredState({ id, source: 'map' })
  }

  // We use a ref so that mapbox callbacks can have access to the latest state
  // If we use useState only the initial value is captured by the callbacks
  const disableAreaHoverRef = useRef(false)

  const timelineEntriesWithCoordinates = timeline?.filter(
    (t) => !!t.getCoordinates(),
  )

  const timelineEntriesWithCoordinatesRef = useRef<Array<AuditResult>>()
  useDeepCompareEffect(() => {
    timelineEntriesWithCoordinatesRef.current = timelineEntriesWithCoordinates
  }, [timelineEntriesWithCoordinates])

  const initialFeatures = [
    entityPoint,
    ...(geoLocationAreas?.features || []),
    ...timelineEntriesWithCoordinates?.map((t) => t.getCoordinates()),
  ]

  const onLoad = (e) => {
    let previouslyHoveredPermitId: number = null

    mapRef.current.on('mousemove', (e) => {
      if (disableAreaHoverRef.current) {
        return
      }

      if (!timelineEntriesWithCoordinatesRef?.current) {
        return
      }
      const featuresUnderMouse = mapRef.current
        ?.getMap()
        ?.queryRenderedFeatures(e.point, {
          layers: timelineEntriesWithCoordinatesRef.current?.map(
            (p) => `areas-${p.id}`,
          ),
        })
      if (!featuresUnderMouse?.length) {
        if (previouslyHoveredPermitId) {
          setHoveredState(null)
          previouslyHoveredPermitId = null
        }
        return
      }
      const permitId = parseInt(
        featuresUnderMouse[0].source.replace('areas-', ''),
      )
      hoveredOnMap(permitId)
      previouslyHoveredPermitId = permitId
    })
  }

  const onClick = (e) => {}

  return (
    <ModalDialogContainer maxWidth={'lg'}>
      <DialogContent>
        <Flex flexDirection='column' height='100%' width='100%'>
          <Flex flex={1}>
            <Box height={'100%'} flex={1} mr={2}>
              <EntityDetailsMap
                projectId={projectId}
                entityPoint={entityPoint}
                geoLocationAreas={geoLocationAreas}
                onLoad={onLoad}
                onClick={onClick}
                mapRef={mapRef}
                initialFeatures={initialFeatures}
                style={{ height: '700px' }}
              >
                {timelineEntriesWithCoordinates?.map((t, index) => {
                  const [longitude, latitude] =
                    t.getCoordinates().geometry.coordinates
                  return (
                    <Marker
                      key={t.id}
                      // VERY IMPORTANT: this makes the bottom point of the marker be exactly at the coordinates
                      anchor='bottom'
                      longitude={longitude}
                      latitude={latitude}
                      onClick={(e) => {
                        // The map on click will not run if this runs
                        e.originalEvent.stopPropagation()
                      }}
                      style={{ zIndex: hoveredState?.id === t.id ? 10 : 1 }}
                    >
                      <Image
                        onMouseOver={(e) => {
                          e.stopPropagation()
                          disableAreaHoverRef.current = true
                          hoveredOnMap(t.id)
                        }}
                        onMouseLeave={() => {
                          disableAreaHoverRef.current = false
                          setHoveredState(null)
                        }}
                        src={
                          hoveredState?.id === t.id
                            ? `/images/icons/markers/permit_marker_purple.svg`
                            : '/images/icons/markers/permit_marker_white.svg'
                        }
                        zIndex={hoveredState?.id === t.id ? 10 : 1}
                      />
                    </Marker>
                  )
                })}
              </EntityDetailsMap>
            </Box>
            {!!hoveredState && hoveredState.source === 'map' && (
              <Box width='500px' overflow='auto' position='absolute' mr={-2}>
                <TimelineCard
                  timelineEntry={timelineEntriesWithCoordinates?.find(
                    (t) => t.id == hoveredState?.id,
                  )}
                />
              </Box>
            )}
            <Box width='450px' overflow='auto' position='relative' mr={-2}>
              <Box position={'absolute'} width='100%'>
                <TransitionGroup>
                  {timelineEntriesWithCoordinates?.map((t) => {
                    return (
                      <TimelineCard
                        key={t.id}
                        timelineEntry={t}
                        onMouseOver={() => {
                          setHoveredState({ id: t.id, source: 'list' })
                        }}
                        onMouseLeave={() => setHoveredState(null)}
                        hovered={hoveredState?.id === t.id}
                      />
                    )
                  })}
                </TransitionGroup>
              </Box>
            </Box>
          </Flex>
        </Flex>
      </DialogContent>
    </ModalDialogContainer>
  )
}

export { TimelineMapModal }
