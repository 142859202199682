import { localizedStrings } from '@core/strings'
import { useRouter } from 'next/router'
import { useDeepCompareMemo } from 'use-deep-compare'
import {
  useDateRangeFilter,
  useProcessTagsFilter,
  useTextInputFilter,
  useUserSearchFilter,
} from '@core/providers/filters'
import { useChecklistTemplatesFilter } from '@core/providers/filters/useChecklistTemplatesFilter'
import { useProjectsFilter } from '@core/providers/filters/useProjectsFilter'
import { useSelectFilter } from '@core/providers/filters'
import { checklistSortOptions } from '@core/utils/checklists-helpers'
import { useQueryParamFilterStore } from 'webapp/components/elements/Filters'
import { useProjectLocationParentOnlyFilter } from '@core/providers/filters/useProjectLocationParentOnlyFilter'
import {
  ChecklistListBusinessUnitScope,
  ChecklistListProjectScope,
} from '@modules/checklists/types'
import { PermitListScopes } from '@modules/permits/PermitList'
import { TableUserSummaryCard } from '@common/Table'
import { useBooleanFilter } from '@core/providers/filters/fundamentals/hooks/useBooleanFilter'
import { ProcessTagColorBadge } from '@common/ProcessTagColorBadge'
import React from 'react'
import { ProcessTagChip } from '@common/ProcessTagChip'

export const useChecklistFilters = (
  scope: PermitListScopes,
  organisationId: number,
  options?: {
    templateFilterProps?: { whitelist?: number[] }
    projectFilterProps?: { whitelist?: number[] }
  },
) => {
  const router = useRouter()
  const { templateFilterProps, projectFilterProps } = options ?? {}

  const filters = {
    processTagIds: useProcessTagsFilter({
      label: localizedStrings.tags,
      scope,
      multiple: true,
      isPinned: true,
      renderItem: (tag) => (
        <>
          <ProcessTagColorBadge color={tag?.color} sx={{ mr: 1 }} />
          {tag?.name}
        </>
      ),
      renderTags: (tags) => {
        return tags.map((tag, index) => {
          return (
            <ProcessTagChip key={index} tag={tag} sx={{ mr: 0.5, my: 0.5 }} />
          )
        })
      },
    }),
    checklistTemplateIds: useChecklistTemplatesFilter({
      label: localizedStrings.checklistType,
      multiple: true,
      organisationId,
      projectIds: scope.type === 'PROJECT' ? [scope.projectId] : undefined,
      whitelist: templateFilterProps?.whitelist,
    }),
    projects: useProjectsFilter({
      label: localizedStrings.project,
      multiple: true,
      organisationId: scope.organisationId,
      businessUnitId: (scope as ChecklistListBusinessUnitScope)?.businessUnitId,
      isHidden: scope.type === 'PROJECT',
      whitelist: projectFilterProps?.whitelist,
    }),
    uuid: useTextInputFilter({
      label: localizedStrings.uniqueCode,
    }),
    dateRange: useDateRangeFilter({
      label: localizedStrings.dateRange,
      dataTest: 'date-range-filter',
    }),
    userId: useUserSearchFilter({
      initialValue: parseInt(router.query.userId as string),
      label: localizedStrings.userInvolved,
      scope,
      renderItem: (user) => <TableUserSummaryCard user={user} />,
    }),
    sort: useSelectFilter({
      label: localizedStrings.sortBy,
      valueToLabel: (n) =>
        checklistSortOptions.find((option) => option.value === n)?.label,
      options: checklistSortOptions.map((option) => option.value),
    }),
    locationIds: useProjectLocationParentOnlyFilter({
      label: localizedStrings.location,
      projectId: (scope as ChecklistListProjectScope)?.projectId,
      multiple: true,
      isHidden: scope.type !== 'PROJECT',
    }),
    hasPermitLinks: useBooleanFilter({
      label: localizedStrings.hasPermitLinks,
    }),
  }

  const queryParamsStore = useQueryParamFilterStore({
    filters,
    queryParams: {
      checklistTemplateIds: 'checklistTemplateIds',
      locationIds: 'locationIds',
      projects: 'projectIds',
      uuid: 'uuid',
      dateRange: 'dateRange',
      sort: 'sort',
      userId: 'userId',
      hasPermitLinks: 'hasPermitLinks',
      processTagIds: 'processTagIds',
    },
    initialValues: {
      checklistTemplateIds: templateFilterProps?.whitelist ?? [],
      projects: projectFilterProps?.whitelist ?? [],
    },
    onChangeOverride: {
      page: 1,
    },
  })

  const payload = useDeepCompareMemo(
    () => ({
      organisationId: organisationId,
      projectIds:
        scope?.type === 'PROJECT'
          ? [scope.projectId]
          : queryParamsStore.values.projects,
      locationIds: queryParamsStore.values.locationIds,
      businessUnitId: (scope as ChecklistListBusinessUnitScope)?.businessUnitId,
      templateIds: queryParamsStore.values.checklistTemplateIds,
      dateRange:
        queryParamsStore?.values?.dateRange?.startTime &&
        queryParamsStore?.values?.dateRange?.endTime
          ? {
              startTime: queryParamsStore?.values?.dateRange?.startTime,
              endTime: queryParamsStore?.values?.dateRange?.endTime,
            }
          : null,
      userId: queryParamsStore?.values?.userId as number,
      uuid: queryParamsStore.values.uuid,
      sort: queryParamsStore.values.sort,
      hasPermitLinks: queryParamsStore.values?.hasPermitLinks,
      processTagIds: queryParamsStore.values.processTagIds,
    }),
    [scope, queryParamsStore.values, router.query?.page, router.query?.size],
  )

  return {
    payload,
    filters,
    queryParamsStore,
  }
}
