import { localizedStrings } from '@core/strings'
import {
  FilterMapInternal,
  SelectFilterInternalArguments,
  FilterInternalArguments,
  FilterStore,
} from './fundamentals'
import { useSelectFilter } from '@core/providers/filters'
import { getDisciplines } from '@core/entities/disciplines/Discipline'

export const useDisciplineFilter: (args: {
  organisationId: number
  filters: { [key: string]: FilterInternalArguments }
  store: FilterStore<FilterMapInternal>
}) => SelectFilterInternalArguments<number, false> = ({
  organisationId,
  filters,
  store,
}) => {
  const views = getDisciplines({ organisationId })
  const showViews =
    !!views?.length && !!Object.values(filters).find((i) => !!i?.disciplineTag)
  return useSelectFilter({
    label: 'Discipline',
    valueToLabel: (viewId) => views.find((v) => v.id === viewId)?.name,
    options: views.map((v) => v.id),
    isPinned: true,
    onChange: (value) => {
      const permitTemplateFilterKey = Object.keys(filters).find((key) => {
        return filters[key]?.disciplineTag === 'PERMIT_TEMPLATE'
      })
      if (!permitTemplateFilterKey) {
        return
      }
      const view = views.find((v) => v.id === value)
      if (!view) {
        store.setFilterValues(
          { [permitTemplateFilterKey]: null },
          { partial: true },
        )
      }
      if (!view?.filters?.permitTemplateIds) {
        return
      }
      store.setFilterValues(
        {
          [permitTemplateFilterKey]: view.filters?.permitTemplateIds,
        },
        { partial: true },
      )
    },
    isHidden: !showViews,
    nothingSelectedText: localizedStrings.all,
  })
}
