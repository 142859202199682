import React, { ReactNode } from 'react'
import EntityDetailsMap from '@modules/maps/EntityDetailsMap'
import { DialogContent } from '@mui/material'
import { Feature, FeatureCollection, Point, Polygon } from 'geojson'
import { ModalDialogContainer } from '@common/modal'
import { Flex } from '@fundamentals'

type PermitDetailsMapModalProps = {
  organisationId: number
  projectId: number
  entityPoint?: Feature<Point>
  geoLocationAreas?: FeatureCollection<Polygon>
  children?: ReactNode
  initialFeatures?: Feature[]
  footerActions?: ReactNode
  auditPointAccuracyRadius?: Feature<Polygon>
}

const EntityMapModal: React.FC<PermitDetailsMapModalProps> = ({
  organisationId,
  projectId,
  entityPoint,
  geoLocationAreas,
  children,
  initialFeatures,
  footerActions,
  auditPointAccuracyRadius,
}) => {
  return (
    <ModalDialogContainer maxWidth={false}>
      <DialogContent>
        <EntityDetailsMap
          projectId={projectId}
          entityPoint={entityPoint}
          geoLocationAreas={geoLocationAreas}
          style={{ height: '85vh', width: '100%' }}
          initialFeatures={initialFeatures}
          auditPointAccuracyRadius={auditPointAccuracyRadius}
        >
          {children}
        </EntityDetailsMap>
        {!!footerActions && (
          <Flex justifyContent='flex-end' sx={{ pb: 2, pr: 2, pt: 0 }}>
            {footerActions}
          </Flex>
        )}
      </DialogContent>
    </ModalDialogContainer>
  )
}

export default EntityMapModal
