import React, { ReactNode, useRef } from 'react'
import { ProjectMapBase } from '@modules/maps/ProjectMapBase'
import { Layer, MapRef, Marker, Source } from 'react-map-gl'
import { Image } from '@fundamentals'
import {
  existingZoneFillStyleWeb,
  existingZoneLineStyleWeb,
} from '@core/utils/map-styling'
import { Feature, FeatureCollection, Point, Polygon } from 'geojson'
import { MapProps } from '@common/material/MapBox'

type ComponentType = MapProps & {
  projectId: number
  onMapClick?: () => void
  initialFeatures?: Feature[]
  children?: ReactNode
  entityPoint?: Feature<Point>
  userCoords?: {
    latitude: number
    longitude: number
  }
  geoLocationAreas?: FeatureCollection<Polygon>
  auditPointAccuracyRadius?: Feature<Polygon>
}

const EntityDetailsMap: React.FC<ComponentType> = ({
  projectId,
  onMapClick,
  initialFeatures: initialFeaturesProps,
  children,
  mapRef: mapRefProp,
  entityPoint,
  geoLocationAreas,
  userCoords,
  auditPointAccuracyRadius,
  ...props
}) => {
  const mapRef = mapRefProp || useRef<MapRef>()

  const initialViewState = entityPoint
    ? {
        longitude: entityPoint?.geometry?.coordinates?.[0],
        latitude: entityPoint?.geometry?.coordinates?.[1],
        zoom: 17,
      }
    : // todo: find way to show the map with a bounding box that contains all the areas
      null

  const fillStyle = existingZoneFillStyleWeb(false)
  const lineStyle = existingZoneLineStyleWeb(false)

  const initialFeatures: Feature[] = [
    ...(initialFeaturesProps || []),
    entityPoint,
    ...(geoLocationAreas?.features || []),
  ]

  return (
    <ProjectMapBase
      mapRef={mapRef}
      projectId={projectId}
      initialViewState={initialViewState}
      initialFeatures={initialFeatures}
      onLoad={(e) => {
        auditPointAccuracyRadius &&
          e.target.addSource('circleData', {
            type: 'geojson',
            data: auditPointAccuracyRadius,
          })

        auditPointAccuracyRadius &&
          e.target.addLayer({
            id: 'circle-fill',
            type: 'fill',
            source: 'circleData',
            paint: {
              'fill-color': 'yellow',
              'fill-opacity': 0.2,
            },
          })
      }}
      onClick={onMapClick}
      {...props}
    >
      {children}
      {!!entityPoint && (
        <Marker
          // VERY IMPORTANT: this makes the bottom point of the marker be exactly at the coordinates
          anchor='bottom'
          longitude={entityPoint?.geometry?.coordinates?.[0]}
          latitude={entityPoint?.geometry?.coordinates?.[1]}
        >
          <Image
            alt='User marker src='
            src='/images/icons/markers/permit_marker_red.svg'
          />
        </Marker>
      )}
      {!!geoLocationAreas?.features?.length &&
        geoLocationAreas.features.map((geoLocationArea, index) => (
          <Source
            type='geojson'
            id={`areas-${projectId}-${index}`}
            key={`areas-${projectId}-${index}`}
            data={geoLocationArea}
          >
            <Layer
              id={`areas-${projectId}-${index}`}
              type='fill'
              paint={fillStyle}
            />
            <Layer
              id={`areas-line-${projectId}-${index}`}
              type='line'
              paint={lineStyle}
            />
          </Source>
        ))}
    </ProjectMapBase>
  )
}

export default EntityDetailsMap
