import { plainToInstance } from 'class-transformer'
import { IRegister } from './IRegister'
import { User } from '@core/entities/user'
import { Entity } from '@core/entities/entity'
import { ParentRegister } from '../ParentRegister'
import {
  RegisterPermittedActions,
  RegisterStatus,
} from '@core/entities/register/Register/types'
import { localizedStrings } from '@core/strings'
import { RegisterDiagram } from '@core/entities/register/RegisterDiagram'

export class Register extends Entity<IRegister> {
  id: number
  name: string
  createdOn: string
  createdBy?: User
  registerTemplate: {
    id: number
    name: string
    code: string
    templateVersionId: number
    version: number
  }
  status?: RegisterStatus
  parentRegisters?: ParentRegister[]
  permittedActions: RegisterPermittedActions[]
  diagrams?: Array<RegisterDiagram>
  projectId?: number
  project?: {
    id: number
    name: string
  }

  public static new(payload: unknown): Register {
    const entity = plainToInstance(Register, payload)

    return entity
  }

  public static statusText(status: RegisterStatus) {
    switch (status) {
      case 'LIVE':
        return localizedStrings.live
      case 'ARCHIVED':
        return localizedStrings.archived
      default:
        return status
    }
  }

  public static statusColor(status: RegisterStatus) {
    switch (status) {
      case 'LIVE':
        return 'success'
      case 'ARCHIVED':
        return 'default'
      default:
        return 'default'
    }
  }

  public canEdit(): boolean {
    return this.permittedActions?.includes('EDIT')
  }

  public canUpdateItems(): boolean {
    return this.permittedActions?.includes('UPDATE_ITEMS')
  }

  public canAddItems(): boolean {
    return this.permittedActions?.includes('ADD_ITEMS')
  }

  public canUploadItems(): boolean {
    return this.permittedActions?.includes('UPLOAD_ITEMS')
  }

  public canDownloadItems(): boolean {
    return this.permittedActions?.includes('DOWNLOAD_ITEMS')
  }

  public canRecalculateFormulas(): boolean {
    return this.permittedActions?.includes('RECALCULATE_FORMULAS')
  }
}
