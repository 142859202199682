import {
  createMutationKeys,
  createQueryKeys,
  mergeQueryKeys,
} from '@lukemorales/query-key-factory'
import { APIAxiosInstance } from '@core/utils/api-axios-instance'
import { RequestTypes } from './requestTypes'
import { createQueryKeyString } from '@core/react-query/queryParams'

const authGroupsQueryKeys = createQueryKeys('auth-groups', {
  list: ({
    organisationId,
    projectId,
    ...rest
  }: RequestTypes['getAuthGroups']) => {
    return {
      queryKey: [organisationId, projectId, rest],
      queryFn: async () => {
        const queryString = createQueryKeyString({
          organisationId,
          projectId,
          ...rest,
        })
        const { data } = await APIAxiosInstance.get(`groups${queryString}`)

        return data
      },
    }
  },
  byId: ({ groupId }: RequestTypes['getAuthGroup']) => {
    return {
      queryKey: [groupId],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(`groups/${groupId}`)

        return data
      },
      contextQueries: {
        getMembers: ({
          groupId,
          ...rest
        }: RequestTypes['getAuthGroupMembers']) => {
          return {
            queryKey: [groupId, rest],
            queryFn: async () => {
              const queryString = createQueryKeyString({
                ...rest,
              })
              const { data } = await APIAxiosInstance.get(
                `groups/${groupId}/members${queryString}`,
              )

              return data
            },
          }
        },
        getGroupPermissions: ({
          groupId,
        }: RequestTypes['getGroupPermissions']) => {
          return {
            queryKey: [groupId],
            queryFn: async () => {
              const { data } = await APIAxiosInstance.get(
                `groups/${groupId}/permissions`,
              )

              return data
            },
          }
        },
        getGroupPermission: ({
          groupId,
          entityId,
          entityType,
        }: RequestTypes['getGroupPermission']) => {
          return {
            queryKey: [groupId, entityId, entityType],
            queryFn: async () => {
              const { data } = await APIAxiosInstance.get(
                `groups/${groupId}/permissions/entity/${entityId}?entityType=${entityType}`,
              )

              return data
            },
          }
        },
      },
    }
  },
})

const authGroupsMutationKeys = createMutationKeys('auth-groups', {
  deleteAuthGroup: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['deleteAuthGroup']) => {
      const { data } = await APIAxiosInstance.delete(
        `groups/${payload.groupId}`,
      )

      return data
    },
  },
  createOranisationAuthGroup: {
    mutationKey: null,
    mutationFn: async ({
      organisationId,
      ...rest
    }: RequestTypes['createOranisationAuthGroup']) => {
      const { data } = await APIAxiosInstance.post(
        `groups/organisations/${organisationId}`,
        {
          ...rest,
        },
      )

      return data
    },
  },
  createProjectAuthGroup: {
    mutationKey: null,
    mutationFn: async ({
      organisationId,
      projectId,
      ...rest
    }: RequestTypes['createProjectAuthGroup']) => {
      const { data } = await APIAxiosInstance.post(
        `groups/organisations/${organisationId}/projects/${projectId}`,
        {
          ...rest,
        },
      )

      return data
    },
  },
  updateAuthGroup: {
    mutationKey: null,
    mutationFn: async ({
      authGroupId,
      ...rest
    }: RequestTypes['updateAuthGroup']) => {
      const { data } = await APIAxiosInstance.put(`groups/${authGroupId}`, {
        ...rest,
      })

      return data
    },
  },
  addAuthGroupMember: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['addAuthGroupMember']) => {
      const { data } = await APIAxiosInstance.put(
        `groups/${payload.groupId}/members`,
        {
          ...payload,
        },
      )

      return data
    },
  },
  deleteAuthGroupMember: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['deleteAuthGroupMember']) => {
      const { data } = await APIAxiosInstance.delete(
        `groups/${payload.groupId}/members/${payload.userId}`,
      )

      return data
    },
  },
  updateAuthGroupPermissions: {
    mutationKey: null,
    mutationFn: async ({
      groupId,
      ...payload
    }: RequestTypes['updateAuthGroupPermissions']) => {
      const { data } = await APIAxiosInstance.put(
        `groups/${groupId}/permissions/entity`,
        payload,
      )

      return data
    },
  },
})

const authGroupsKeys = mergeQueryKeys(
  authGroupsQueryKeys,
  authGroupsMutationKeys,
)['auth-groups']

export { authGroupsKeys }
