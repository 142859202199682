import React, { ReactNode } from 'react'
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
} from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import { ModalDialogContainer } from '@common/modal/ModalDialogContainer'
import { Box, Image } from '@common/fundamentals'
import { useModal } from '..'
import axios from 'axios'
import fileDownload from 'js-file-download'
import { localizedStrings } from '@core/strings'
import { useToast } from '@core/toast'
import { ObjectDetectionChips } from '@elements/ObjectDetection'

type ComponentType = {
  title: string
  s3Key?: string
  previewUrl: string
  footerActions?: ReactNode
  canDownload?: boolean
}

const ImageModal: React.FC<ComponentType> = ({
  title,
  s3Key,
  previewUrl,
  footerActions,
  canDownload,
}) => {
  const toast = useToast()
  const { hideModal } = useModal()

  return (
    <ModalDialogContainer
      preventDialogClose
      PaperProps={{
        sx: {
          maxWidth: '100%',
        },
      }}
    >
      {title && (
        <DialogTitle
          variant='h5'
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          sx={{ pr: 1, fontSize: 20 }}
        >
          {title}
          <ObjectDetectionChips s3Key={s3Key} />
          <Box flexGrow={1} />
          <IconButton aria-label='close-modal' onClick={hideModal}>
            <ClearIcon />
          </IconButton>
        </DialogTitle>
      )}

      <DialogContent sx={{ pb: 1 }}>
        <Image
          width='100%'
          height='100%'
          maxHeight='calc(100vh - 220px)'
          sx={{
            objectFit: 'contain',
          }}
          src={previewUrl}
        />
      </DialogContent>

      {(!!footerActions || canDownload) && (
        <DialogActions sx={{ pb: 2, pr: 3 }}>
          {footerActions}
          {canDownload && (
            <Button
              variant='contained'
              onClick={async () => {
                const filename = previewUrl.split('-proof/')[1].split('?')[0]

                try {
                  const response = await axios.get(previewUrl, {
                    responseType: 'blob',
                    headers: {
                      'Cache-Control': 'no-cache',
                    },
                  })
                  fileDownload(response.data, filename)
                } catch (e) {
                  toast.error(e)
                }
              }}
            >
              {localizedStrings.download}
            </Button>
          )}
        </DialogActions>
      )}
    </ModalDialogContainer>
  )
}

export default ImageModal
