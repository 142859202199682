import { Entity } from '@core/entities/entity'
import { plainToInstance, Type } from 'class-transformer'
import { User } from '@core/entities/user'
import { IPermitTemplateRole } from './IPermitTemplateRole'
import { localizedStrings } from '@core/strings'

export type PermitTemplateRolePermissionType =
  | 'PERIODIC_CHECK_SUBMIT'
  | 'ADD_PARTICIPANT'
  | 'POST_PERMIT_CHECK_SUBMIT'
  | 'DECLARE_WORK_COMPLETE'
  | 'REQUEST_EXTENSION'
  | 'DO_PRE_START_TASKS'

export class PermitTemplateRole extends Entity<IPermitTemplateRole> {
  id: number
  templateVersionId: number
  name: string
  description?: string
  briefingText?: string
  permissions: Array<PermitTemplateRolePermissionType>
  transferOwnershipOnBriefing: boolean
  groupIds?: Array<number>

  @Type(() => User)
  createdBy: User
  createdOn: string
  @Type(() => User)
  modifiedBy: User
  modifiedOn: string

  public static getPermissionText(
    permission: PermitTemplateRolePermissionType,
  ): string {
    switch (permission) {
      case 'PERIODIC_CHECK_SUBMIT':
        return localizedStrings.submitPeriodicChecks
      case 'ADD_PARTICIPANT':
        return localizedStrings.addParticipants
      case 'POST_PERMIT_CHECK_SUBMIT':
        return localizedStrings.submitPostPermitChecks
      case 'DECLARE_WORK_COMPLETE':
        return localizedStrings.declareWorkComplete
      case 'REQUEST_EXTENSION':
        return localizedStrings.requestExtensions
      case 'DO_PRE_START_TASKS':
        return localizedStrings.doPreStartTasks
      default:
        return ''
    }
  }

  public static new(payload: unknown): PermitTemplateRole {
    const entity = plainToInstance(PermitTemplateRole, payload)

    return entity
  }
}
