import { createQueryKeys } from '@lukemorales/query-key-factory'
import { APIAxiosInstance } from '@core/utils/legacy-api-axios-instance'
import { RequestTypes } from './requestTypes'

export const permitImageDuplicatesKeys = createQueryKeys(
  'permit-image-duplication',
  {
    byPermit: (payload: RequestTypes['useGetPermitImageDuplicates']) => {
      return {
        queryKey: [payload.permitId],
        queryFn: async () => {
          const { data } = await APIAxiosInstance.get(
            `permits/${payload.permitId}/images/duplicates`,
            {},
          )

          return data
        },
      }
    },
  },
)
