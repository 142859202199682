import { localizedStrings } from '@core/strings'
import React from 'react'
import { PermitTimelineTabProps } from '../types'
import { Box } from '@fundamentals'
import { Timeline, PermitTimelineItem } from '@common/Timeline'
import { Button } from '@common/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { useModal } from '@common/GlobalModal'
import { TimelineMapModal } from '@modules/maps/TimelineMapModal'
import { usePermitTimeline } from '@core/react-query/features/permits/permit/hooks/usePermitTimeline'

export const TimelineTab: React.FC<PermitTimelineTabProps> = ({ permit }) => {
  const { showModal } = useModal()

  const {
    data: timeline,
    error,
    isLoading,
  } = usePermitTimeline({ permitId: permit?.id })

  const hasItemsWithCoordinates =
    timeline?.filter((t) => !!t?.getCoordinates())?.length > 0

  return (
    <>
      {hasItemsWithCoordinates && (
        <Button
          sx={{ mb: 2 }}
          startIcon={<LocationOnIcon />}
          onClick={() => {
            showModal(
              <TimelineMapModal
                projectId={permit.project.id}
                entityPoint={permit.getW3wLocation()}
                geoLocationAreas={permit.getGeolocationAreas()}
                timeline={timeline}
              />,
            )
          }}
        >
          {localizedStrings.viewOnMap}
        </Button>
      )}
      <ErrorMessage>{error}</ErrorMessage>
      <Timeline loading={isLoading}>
        {timeline?.map((timelineItem, index) => (
          <Box key={timelineItem.id} mb={2}>
            <PermitTimelineItem timelineItem={timelineItem} permit={permit} />
          </Box>
        ))}
      </Timeline>
    </>
  )
}
