import { localizedStrings } from '@core/strings'
export type colorTypes =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning'

export const getTimelineStatusColor = (
  status: string,
  colors: {
    warning?: {
      normal: colorTypes
      alpha: string
    }
    error?: {
      normal: colorTypes
      alpha: string
    }
    success?: {
      normal: colorTypes
      alpha: string
    }
    primary?: {
      normal: colorTypes
      alpha: string
    }
    info?: {
      normal: colorTypes
      alpha: string
    }
  },
) => {
  // const theme: any = useTheme()
  switch (status) {
    case 'PERMIT_REQUESTED':
    case 'PERMIT_EXTENSION_REQUESTED':
    case 'SENT_TO_PERMIT_HOLDER':
    case 'PERMIT_CHANGES_REQUESTED':
    case 'PERMIT_TRANSFER_INITIATED':
    case 'IMAGE_WARNINGS_CREATED':
      return {
        chipColor: colors.warning.normal,
        cardColor: colors.warning.alpha,
      }

    case 'PERMIT_REJECTED':
    case 'PERMIT_EXTENSION_REJECTED':
    case 'PERMIT_TRANSFER_DECLINED':
    case 'PERMIT_EXPIRED':
    case 'PERMIT_TRANSFER_REJECTED':
    case 'PERMIT_TRANSFER_CANCELLED':
    case 'PARTICIPANT_REMOVED':
    case 'PERMIT_DISCARDED':
    case 'PERMIT_SUSPENDED':
    case 'PERMIT_SUSPENSION_RESUME_REQUEST_DECLINED':
      return {
        chipColor: colors.error.normal,
        cardColor: colors.error.alpha,
      }

    case 'PERMIT_APPROVED':
    case 'PERMIT_EXTENSION_APPROVED':
    case 'PERMIT_OPENED':
    case 'PERMIT_POST_PERMIT_CHECK_COMPLETE':
    case 'PERMIT_RECEIVED':
    case 'PERMIT_CHANGES_ADDRESSED':
    case 'PERMIT_PERIODIC_CHECKS_SUBMITTED':
    case 'PARTICIPANT_BRIEFED':
    case 'PERMIT_TRANSFER_ACCEPTED':
    case 'PERMIT_TRANSFER_APPROVED':
    case 'PERMIT_HOLDER_CHANGED':
    case 'PERMIT_SUSPENDED_RESUME':
    case 'PERMIT_SUSPENSION_RESUME_REQUEST_APPROVED':
    case 'CREATED':
    case 'UPDATED':
      return {
        chipColor: colors.success.normal,
        cardColor: colors.success.alpha,
      }

    case 'PERMIT_SIGN_OFF_COMPLETE':
    case 'WORK_COMPLETE':
    case 'CHECKLIST_COMPLETED':
      return {
        chipColor: colors.primary.normal,
        cardColor: colors.primary.alpha,
      }

    case 'COMMENT_CREATED':
      return {
        chipColor: colors?.info.normal,
        cardColor: colors?.info.alpha,
      }

    default:
      return {
        chipColor: colors.primary.normal,
        cardColor: colors.primary.alpha,
      }
  }
}

export const getTextForTimelineAction = (
  action: string,
  inputType?: string,
) => {
  switch (action) {
    case 'PERMIT_SIGNED':
      return localizedStrings.participantSigned
    case 'PERMIT_REQUESTED':
      return localizedStrings.requested
    case 'PERMIT_APPROVED':
      return localizedStrings.approved
    case 'PERMIT_REJECTED':
      return localizedStrings.rejected
    case 'PERMIT_EXTENSION_REQUESTED':
      return localizedStrings.requestedExtension
    case 'PERMIT_EXTENSION_APPROVED':
      return localizedStrings.approvedExtension
    case 'PERMIT_EXTENSION_REJECTED':
      return localizedStrings.rejectedExtension
    case 'PERMIT_OPENED':
      return localizedStrings.opened
    case 'PERMIT_EXPIRED':
      return localizedStrings.expired
    case 'PERMIT_POST_PERMIT_CHECK_COMPLETE':
      return localizedStrings.postPermitChecks
    case 'PERMIT_SIGN_OFF_COMPLETE':
      return localizedStrings.finalSignOff
    case 'WORK_COMPLETE':
      return localizedStrings.permitClosed
    case 'PERMIT_CANCELLED':
      return localizedStrings.cancelled
    case 'PERMIT_CHECKLIST_DONE':
      return localizedStrings.checklistCompleted
    case 'SENT_TO_PERMIT_HOLDER':
      return localizedStrings.sentToPermitHolder
    case 'PERMIT_RECEIVED':
      return localizedStrings.permitReceived
    case 'PERMIT_CHANGES_REQUESTED':
      return localizedStrings.changesRequested
    case 'PERMIT_CHANGES_ADDRESSED':
      return localizedStrings.changesAddressed
    case 'PERMIT_HOLDER_CHANGED':
      return localizedStrings.permitHolderChanged
    case 'PERMIT_PERIODIC_CHECKS_SUBMITTED':
      return localizedStrings.periodicCheck
    case 'PARTICIPANT_BRIEFED':
      return localizedStrings.participantBriefed
    case 'PERMIT_TRANSFER_REJECTED':
      return localizedStrings.permitTransferRejected
    case 'PERMIT_TRANSFER_INITIATED':
      return localizedStrings.permitTransferInitiated
    case 'PERMIT_TRANSFER_CANCELLED':
      return localizedStrings.permitTransferCancelled
    case 'PERMIT_TRANSFER_APPROVED':
      return localizedStrings.permit
    case 'PERMIT_TRANSFER_DECLINED':
      return localizedStrings.permitTransferDeclined
    case 'PERMIT_TRANSFER_ACCEPTED':
      return localizedStrings.permitTransferAccepted
    case 'PARTICIPANT_REMOVED':
      return localizedStrings.participantRemoved
    case 'PERMIT_DISCARDED':
      return localizedStrings.discarded
    case 'CHECKLIST_COMPLETED':
      return localizedStrings.complete
    case 'PERMIT_SUSPENDED':
      return localizedStrings.suspended
    case 'PERMIT_SUSPENDED_RESUME':
      return localizedStrings.resumed
    case 'PERMIT_SUSPENDED_RESUME_REQUESTED':
      return localizedStrings.resumeRequested
    case 'PERMIT_SUSPENSION_RESUME_REQUEST_DECLINED':
      return localizedStrings.resumeRequestRejected
    case 'PERMIT_SUSPENSION_RESUME_REQUEST_APPROVED':
      return localizedStrings.resumeRequestApproved
    case 'PERMIT_SHORTENED':
      return localizedStrings.updated
    case 'CREATED':
      return inputType === 'CSV_UPLOAD'
        ? localizedStrings.itemCreatedViaCsv
        : localizedStrings.itemCreated
    case 'UPDATED':
      return inputType === 'CSV_UPLOAD'
        ? localizedStrings.itemUpdatedViaCsv
        : localizedStrings.itemUpdated
    case 'FORMULAS_RECALCULATED':
      return localizedStrings.formulasRecalculated
    case 'EMAIL_NOTIFICATION_SENT':
      return localizedStrings.emailNotificationSent
    case 'COMMENT_CREATED':
      return localizedStrings.newComment
    case 'ARCHIVED':
      return localizedStrings.itemArchived
    case 'IMAGE_WARNINGS_CREATED':
      return localizedStrings.warningDetected
    default:
      return action
  }
}
