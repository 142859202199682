import { useQuery } from '@tanstack/react-query'
import { permitImageDuplicatesKeys } from '../permitImageDuplicatesKeys'

import { RequestTypes } from '../requestTypes'
import { IPermitImageDuplicates } from '../../../../../entities/permit/PermitImageDuplicates/IPermitImageDuplicates'
import { UseQueryOptions } from '@tanstack/react-query/src/types'

export const useGetPermitImageDuplicates = (
  payload: RequestTypes['useGetPermitImageDuplicates'],
  options?: UseQueryOptions<IPermitImageDuplicates, string>,
) => {
  return useQuery({
    ...permitImageDuplicatesKeys.byPermit(payload),
    ...options,
  })
}
