import { createQueryKeys } from '@lukemorales/query-key-factory'
import { APIAxiosInstance } from '@core/utils/api-axios-instance'
import { RequestTypes } from './requestTypes'
import { createQueryKeyString } from '@core/react-query/queryParams'
import { PageResponse } from '@core/entities/pagination'
import { IChecklist } from '@core/entities/checklist/Checklist'

export const checklistsKeys = createQueryKeys('checklists', {
  allChecklists: ({
    organisationId,
    projectIds,
    page,
    size,
    dateRange,
    ...filters
  }: RequestTypes['getAllChecklists']) => {
    return {
      queryKey: [
        organisationId,
        projectIds,
        { ...filters, ...dateRange },
        page,
        size,
      ],
      queryFn: async ({ pageParam = 1 }) => {
        const queryParams = createQueryKeyString({
          page: page ? page : pageParam,
          size,
          organisationId,
          projectIds,
          ...dateRange,
          ...filters,
        })
        const { data } = await APIAxiosInstance.get(`checklists${queryParams}`)

        return data as PageResponse<IChecklist>
      },
    }
  },
  allChecklistsPage: ({
    organisationId,
    projectIds,
    page,
    size,
    dateRange,
    ...filters
  }: RequestTypes['getAllChecklists']) => {
    return {
      queryKey: [
        organisationId,
        projectIds,
        { ...filters, ...dateRange },
        page,
        size,
      ],
      queryFn: async ({ pageParam = 1 }) => {
        const queryParams = createQueryKeyString({
          page: page ? page : pageParam,
          size,
          organisationId,
          projectIds,
          ...dateRange,
          ...filters,
        })
        const { data } = await APIAxiosInstance.get(`checklists${queryParams}`)

        return data as PageResponse<IChecklist>
      },
    }
  },
  drafts: ({
    page,
    size,
    organisationId,
    projectIds,
  }: RequestTypes['getDrafts']) => {
    return {
      queryKey: [organisationId, projectIds, page, size],
      queryFn: async ({ pageParam = 1 }) => {
        const queryParams = createQueryKeyString({
          page: page ? page : pageParam,
          size,
          organisationId,
          projectIds,
        })
        const { data } = await APIAxiosInstance.get(
          `checklists/drafts${queryParams}`,
        )

        return data
      },
    }
  },
  draftsPage: ({
    page,
    size,
    organisationId,
    projectIds,
  }: RequestTypes['getDrafts']) => {
    return {
      queryKey: [organisationId, projectIds, page, size],
      queryFn: async ({ pageParam = 1 }) => {
        const queryParams = createQueryKeyString({
          page: page ? page : pageParam,
          size,
          organisationId,
          projectIds,
        })
        const { data } = await APIAxiosInstance.get(
          `checklists/drafts${queryParams}`,
        )

        return data
      },
    }
  },
  byPermit: ({ permitId }: RequestTypes['getChecklistsByPermit']) => {
    return {
      queryKey: [permitId],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(
          `checklists/permits/${permitId}`,
        )

        return data.items
      },
    }
  },
  byId: ({ checklistId }: RequestTypes['getChecklistById']) => {
    return {
      queryKey: [checklistId],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(`checklists/${checklistId}`)

        return data
      },
    }
  },
  projectMapChecklists: ({
    projectId,
    checklistTemplateIds,
    createdOnRangeStartTime,
    createdOnRangeEndTime,
    polygon,
  }: RequestTypes['getProjectMapChecklists']) => {
    return {
      queryKey: [
        projectId,
        createdOnRangeStartTime,
        createdOnRangeEndTime,
        polygon,
        checklistTemplateIds,
      ],
      queryFn: async () => {
        const queryParams = createQueryKeyString({
          projectId,
          createdOnRangeStartTime,
          createdOnRangeEndTime,
          polygon,
          checklistTemplateIds,
        })
        const { data } = await APIAxiosInstance.get(
          `checklists/map${queryParams}`,
        )

        return data
      },
    }
  },
  getRecentParticipants: (
    payload: RequestTypes['getChecklistParticipants'],
  ) => {
    return {
      queryKey: [payload.term, payload.projectId, payload.authGroupsIds],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(
          `checklists/participants/recent`,
          {
            params: payload,
            paramsSerializer: { indexes: null },
          },
        )

        return data.participants
      },
    }
  },
  getChecklistReport: ({
    reportingType,
    organisationId,
    projectIds,
    ...filters
  }: RequestTypes['getChecklistReport']) => {
    return {
      queryKey: [reportingType, organisationId, projectIds, filters],
      queryFn: async () => {
        const queryString = createQueryKeyString({
          organisationId,
          projectIds,
          ...filters,
        })
        const { data } = await APIAxiosInstance.get(
          `reporting/checklists/${reportingType}${queryString}`,
        )

        return data
      },
    }
  },
})
