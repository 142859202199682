const BBV_ORGANISATION_ID = 18268
const BALFOUR_BEATTY_ORGANISATION_ID = 9476

export const getDisciplines = ({
  organisationId,
}: {
  organisationId: number
}): Discipline[] => {
  if (organisationId === BBV_ORGANISATION_ID) {
    return [
      {
        id: 456,
        name: 'All',
        filters: {
          permitTemplateIds: [],
        },
      },
      {
        id: 123,
        name: 'Utilities',
        filters: {
          permitTemplateIds: [4843, 5656, 4795],
        },
      },
      {
        id: 234,
        name: 'Temporary Works',
        filters: {
          permitTemplateIds: [
            140384, 133992, 134120, 140168, 139450, 139536, 139586, 139618,
            140260, 153445, 140156, 140131, 140222, 277349, 277311, 277348,
            582996,
          ],
        },
      },
      {
        id: 567,
        name: 'Environmental',
        filters: {
          permitTemplateIds: [261075, 457644],
        },
      },
    ]
  }

  if (organisationId === BALFOUR_BEATTY_ORGANISATION_ID) {
    const electrical = [2731, 3214, 1920, 1921]
    const mechanical = [4252, 2936, 4253, 4254]

    return [
      {
        id: 456,
        name: 'All',
        filters: {
          permitTemplateIds: [],
        },
      },
      {
        id: 789,
        name: 'Electrical',
        filters: {
          permitTemplateIds: electrical,
        },
      },
      {
        id: 8910,
        name: 'Mechanical',
        filters: {
          permitTemplateIds: mechanical,
        },
      },
      {
        id: 91011,
        name: 'Electrical & Mechanical',
        filters: {
          permitTemplateIds: [...electrical, ...mechanical],
        },
      },
    ]
  }

  return []
}

type Discipline = {
  id: number
  name: string
  filters: {
    permitTemplateIds: number[]
  }
}
