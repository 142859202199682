import { Avatar } from '@common/material'
import { Text } from '@common/fundamentals'

type UserAvatarProps = {
  user: {
    firstName: string
    lastName: string
  }
}
export const UserAvatar: React.FC<UserAvatarProps> = ({ user }) => {
  const capitalFirstLetter = (name: string) => {
    if (!name) {
      return ''
    }
    return name?.[0]?.toUpperCase()
  }

  const userInitials =
    capitalFirstLetter(user?.firstName) + capitalFirstLetter(user?.lastName)

  return (
    <Avatar sx={{ bgcolor: '#A4B5D2' }}>
      <Text>{userInitials}</Text>
    </Avatar>
  )
}
