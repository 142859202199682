import { Box, Flex } from '@fundamentals'
import React from 'react'
import { Profile } from 'webapp/components/common/Profile'
import { NavbarProps } from './types'

const Navbar: React.FC<NavbarProps> = ({ children, variant }) => {
  const bgcolor = variant === 'dashboard' ? '#1976D2' : '#FAFAFA'
  return (
    <>
      <Box
        position='sticky'
        zIndex={10}
        top={0}
        bgcolor={variant === 'dashboard' && '#1976D2'}
      >
        <Flex alignItems='center' ml={3} height={68} bgcolor={bgcolor}>
          {children}
          <Box flexGrow={1} />
          <Profile variant={variant} />
        </Flex>
      </Box>
    </>
  )
}

export { Navbar }
