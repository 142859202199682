import { useEffect, useState } from 'react'

type GlobalConstants = {
  pdf_cache_expiration_in_seconds: number
  max_question_attachment_size_in_bytes: number
  max_number_of_proofs: number
  max_number_of_documents: number
  mapbox_default_style_url: string
  mapbox_default_center_latitude: number
  mapbox_default_center_longitude: number
  mapbox_default_zoom: number
  search_debounce_time: number
}

type UseConstants = {
  constants: GlobalConstants
}

export default function useConstants(): UseConstants {
  // We use a global_constants object in Flagsmith
  // You can see it here: https://app.flagsmith.com/project/3711/environment/N4YCCJMHVwdQGpZtauqx4Z/features?feature=20422
  // If you want to test different values, there is a global_constant_override segment that can be used

  const [globalConstants, setGlobalConstants] = useState<GlobalConstants>({
    pdf_cache_expiration_in_seconds: 7200,
    max_question_attachment_size_in_bytes: 10000000,
    max_number_of_documents: 50,
    max_number_of_proofs: 15,
    mapbox_default_style_url:
      'mapbox://styles/siteassist/clqcc6dhl00bh01nwbqck4aum',
    mapbox_default_center_latitude: 52.682,
    mapbox_default_center_longitude: -3.114,
    mapbox_default_zoom: 17,
    search_debounce_time: 1000,
  })

  const getGlobalConstants = () => {
    const flagsmithValues = API.flagsmith.getValue('global_constants')
    if (flagsmithValues) {
      const constantOverrides = JSON.parse(String(flagsmithValues))
      setGlobalConstants({
        ...globalConstants,
        ...constantOverrides,
      })
    }
  }

  useEffect(() => {
    getGlobalConstants()
  }, [])

  return {
    constants: globalConstants,
  }
}
