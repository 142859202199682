import { localizedStrings } from '@core/strings'
import React, { useState } from 'react'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@common/material'
import { Box, Text } from '@fundamentals'
import ClearIcon from '@mui/icons-material/Clear'
import { useModal } from '@common/GlobalModal'
import { SignatureEdit } from 'webapp/components/common/Signature'
import useConstants from '@core/providers/useConstants'
import Upload from 'components/upload/Upload'
import { useUploadMedia } from '@core/react-query/features/media'
import BackdropLoader from '@common/fundamentals/BackdropLoader'
import {
  ResumePermitSubmitData,
  SuspendPermitSubmitData,
} from '@modules/permitDetails/types'
import { useGetProfile } from '@core/react-query/features/profile/hooks/useGetProfile'
import { Permit } from '@core/entities/permit/Permit'
import { ModalDialogContainer } from '@common/modal'
import { ProofType } from '@core/entities/template/TemplateQuestion'

type ComponentType = {
  // we keep the old type here for now, until the full permit details refactor is done
  permit: Permit
  onSubmit?: (data: SuspendPermitSubmitData | ResumePermitSubmitData) => void
}

type ProofProps = {
  url: string
  proof: File
  type?: ProofType
}

export const SuspendResumePermitModal: React.FC<ComponentType> = ({
  permit,
  onSubmit,
}) => {
  const [reason, setReason] = useState<string>('')
  const [files, setFiles] = useState<ProofProps[]>([])
  const { hideModal } = useModal()
  const { constants } = useConstants()
  const { data: profile } = useGetProfile()
  const uploadMedia = useUploadMedia()

  const handleAddProof = (value: string, file: File, fileType: string) => {
    setFiles([
      ...files,
      {
        proof: file,
        url: value,
        type: fileType === 'application/pdf' ? 'pdf' : 'img',
      },
    ])
  }

  const handleRemoveProof = (index: number) => {
    setFiles(files.filter((_, i) => i !== index))
  }

  const handleSubmit = async () => {
    const uploadedProofs = await Promise.all(
      files.map(async (file: any) => {
        if (!file.downloadUrl) {
          const uploadedFileKey = await uploadMedia.mutateAsync({
            file: file.proof,
            folderName: `${permit.template.id}-${
              file.type === 'pdf' ? 'documents' : 'proof'
            }`,
          })

          return { ...file, key: uploadedFileKey }
        }
        return file
      }),
    )

    const attachmentsKeys = uploadedProofs.map((file) => file.key) || []

    if (permit.status === 'SUSPENDED') {
      onSubmit({ comment: reason, attachmentsKeys })
    } else {
      onSubmit({
        comment: reason,
        attachmentsKeys,
        // we'll keep suspensionReasonCategory as GENERIC for now. stage 2 we will add a dropdown to select the reason
        suspensionReasonCategory: 'GENERIC',
      })
    }
  }

  return (
    <ModalDialogContainer>
      <BackdropLoader open={uploadMedia.isLoading} />
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        {permit.status === 'SUSPENDED'
          ? localizedStrings.resumePermit
          : localizedStrings.suspendPermit}
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <TextField
          name='reason'
          label={
            permit.status === 'SUSPENDED'
              ? localizedStrings.resumePermitReason
              : localizedStrings.suspendPermitReason
          }
          value={reason}
          multiline
          rows={3}
          required
          sx={{ width: '100%', mt: 1, mb: 2 }}
          placeholder={localizedStrings.enterReason}
          onChange={(e) => setReason(Utils.safeParseEventValue(e))}
        />
        <Box sx={{ mb: 2 }}>
          <Upload
            onChange={handleAddProof}
            value={files?.map((file) => ({
              url: file.url,
              type: file.type,
            }))}
            disabled={files?.length >= constants.max_number_of_proofs}
            onClear={handleRemoveProof}
            limitBytes={constants.max_question_attachment_size_in_bytes}
            allowImageAndPdf
          />
        </Box>
        <Text sx={{ fontSize: 12, mt: 3 }} color='text.secondary' gutterBottom>
          {permit.status === 'SUSPENDED'
            ? localizedStrings.resumePermitDeclaration
            : localizedStrings.suspendPermitDeclaration}
        </Text>
        <SignatureEdit readonly value={profile?.signatureUrl} />
      </DialogContent>

      <DialogActions sx={{ pb: 2, pr: 3 }}>
        <Button variant='outlined' onClick={hideModal}>
          {localizedStrings.cancel}
        </Button>
        <Button
          variant='contained'
          disabled={!reason?.length}
          onClick={handleSubmit}
        >
          {localizedStrings.submit}
        </Button>
      </DialogActions>
    </ModalDialogContainer>
  )
}
