import { localizedStrings } from '@core/strings'
import React from 'react'
import { useGetAllProjects } from '@core/react-query/features/projects'
import { MenuItem, TextField } from '@common/material'
import { TextFieldProps } from '@mui/material/TextField'
import { Text } from '@fundamentals'
import { Section } from '@common/Section'

type ProjectPickerProps = TextFieldProps & {
  organisationId: number
  readOnly?: boolean
}

const ProjectPicker: React.FC<ProjectPickerProps> = ({
  organisationId,
  readOnly,
  error,
  ...props
}) => {
  const { data } = useGetAllProjects({ organisationId })

  if (readOnly) {
    const projectName = data?.find(
      (project) => project.id === props.value,
    )?.name

    return (
      <Section sx={props?.sx} label={localizedStrings.project}>
        <Text>{projectName}</Text>
      </Section>
    )
  }

  return (
    <TextField
      label={localizedStrings.project}
      data-test='select-project-dropdown-btn'
      select
      FormHelperTextProps={{ error, sx: { pt: 0.5, pb: 2 } }}
      error={error}
      required
      {...props}
    >
      {data?.map((project, index) => {
        return (
          <MenuItem
            key={project.id}
            value={project.id}
            data-test={`select-project-dropdown-option-${index}`}
          >
            <Text>{project.name}</Text>
          </MenuItem>
        )
      })}
    </TextField>
  )
}

export { ProjectPicker }
