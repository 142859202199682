'use client'
import { localizedStrings } from '@core/strings'
import React, { useState } from 'react'
import { useModal } from '@common/GlobalModal'
import {
  DialogTitle,
  IconButton,
  DialogContent,
  Button,
  MenuItem,
  Divider,
  Checkbox,
} from '@common/material'
import {
  ArrowBack,
  Clear,
  Folder as FolderIcon,
  InsertDriveFile,
} from '@mui/icons-material'
import { ModalDialogContainer } from '@common/modal'
import { useFileSystem } from '@core/react-query/features/folders/hooks'
import BackdropLoader from '@common/fundamentals/BackdropLoader'
import { Folder } from '@core/entities/folders/Folder'
import { Box, Flex, Text } from '@fundamentals'
import { useTheme } from '@mui/material'
import { cyan } from '@mui/material/colors'
import { PageTitle } from '@elements'
import moment from 'moment'
import { SiteBoxDocument } from '@core/entities/SiteBoxDocument'

export type SiteBoxDocumentSelectEntry = {
  id: number
  key: string
  siteBoxDocumentVersionId: number
  type: string
  downloadUrl: string
  getUrl: string
}
interface ComponentType {
  organisationId: number
  projectId: number
  multiSelect?: boolean
  currentSelectedSiteBoxDocuments?: Array<SiteBoxDocumentSelectEntry>
  handleSiteBoxDocumentSelection: (
    siteboxDocuments: SiteBoxDocumentSelectEntry | SiteBoxDocumentSelectEntry[],
  ) => void
}

export const SelectSiteBoxDocumentModal: React.FC<ComponentType> = ({
  organisationId,
  projectId,
  multiSelect = false,
  currentSelectedSiteBoxDocuments = [],
  handleSiteBoxDocumentSelection,
}) => {
  const { hideModal } = useModal()
  const theme = useTheme()
  const [currentFolderId, setCurrentFolderId] = useState<number>(-1)
  const [selectedSiteBoxDocuments, setSelectedSiteBoxDocuments] = useState<
    Array<SiteBoxDocumentSelectEntry>
  >(currentSelectedSiteBoxDocuments)

  const { folder, isLoading } = useFileSystem({
    organisationId,
    projectId,
    type: 'SITE_BOX',
    folderId: currentFolderId,
  })

  if (isLoading) return <BackdropLoader open />

  const liveFiles = folder.files.filter(
    (file) => file instanceof SiteBoxDocument && file.isLive(),
  )

  const handleSelect = (item: SiteBoxDocument) => {
    if (multiSelect) {
      const isAlreadySelected = selectedSiteBoxDocuments?.some(
        (doc) => doc.id === item.id,
      )
      if (isAlreadySelected) {
        setSelectedSiteBoxDocuments(
          selectedSiteBoxDocuments.filter((doc) => doc.id !== item.id),
        )
      } else {
        setSelectedSiteBoxDocuments([
          ...selectedSiteBoxDocuments,
          {
            id: item.id,
            siteBoxDocumentVersionId: item.currentVersion?.id,
            key: item.currentVersion?.documentKey,
            type: 'application/pdf',
            downloadUrl: item.currentVersion?.documentDownloadUrl,
            getUrl: item.currentVersion?.documentGetUrl,
          },
        ])
      }
    } else {
      handleSiteBoxDocumentSelection({
        id: item.id,
        siteBoxDocumentVersionId: item.currentVersion?.id,
        key: item.currentVersion?.documentKey,
        type: 'application/pdf',
        downloadUrl: item.currentVersion?.documentDownloadUrl,
        getUrl: item.currentVersion?.documentGetUrl,
      })
      hideModal()
    }
  }

  const handleConfirmSelection = () => {
    handleSiteBoxDocumentSelection(selectedSiteBoxDocuments)
    hideModal()
  }

  return (
    <ModalDialogContainer PaperProps={{ sx: { maxWidth: '80%', flex: 1 } }}>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        <Box>
          {localizedStrings.addDocument}
          <Text variant='body2' pt={1}>
            {localizedStrings.selectAnExistingDocumentOrUpload}
          </Text>
        </Box>

        <IconButton aria-label='close-modal' onClick={hideModal}>
          <Clear />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}
      >
        {!folder.isRootFolder() && (
          <Flex alignItems='center' mb={1}>
            <Button
              variant='outlined'
              size='medium'
              startIcon={<ArrowBack />}
              sx={{
                '.MuiButton-startIcon': { margin: 0 },
                py: 0.3,
                px: 0.9,
                minWidth: 0,
                mr: 2,
              }}
              onClick={() => setCurrentFolderId(folder.getParentFolderId())}
            />

            <PageTitle
              sx={{
                m: 0,
                mr: 1,
              }}
              title={folder.currentFolder.displayName}
            />
          </Flex>
        )}
        {[...folder.folders, ...liveFiles].map((item) => {
          const isFolder = item instanceof Folder
          const modifiedOn = moment(item.modifiedOn).format('DD/MM/YYYY HH:MM')

          return (
            <>
              <MenuItem
                onClick={(e) => {
                  if (isFolder) {
                    setCurrentFolderId(item.id)
                  } else {
                    if (item instanceof SiteBoxDocument) {
                      handleSelect(item)
                    }
                  }
                }}
                value={item.id}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  alignItems: 'center',
                  px: isFolder ? 2 : 1,
                }}
              >
                <Flex py={0.5} alignItems='center'>
                  {!isFolder && multiSelect && (
                    <Checkbox
                      checked={selectedSiteBoxDocuments?.some(
                        (doc) => doc.id === item.id,
                      )}
                      onClick={(e) => {
                        e.stopPropagation()

                        if (item instanceof SiteBoxDocument) {
                          handleSelect(item)
                        }
                      }}
                    />
                  )}

                  {isFolder ? (
                    <FolderIcon sx={{ color: cyan[400] }} />
                  ) : (
                    <InsertDriveFile sx={{ color: theme.palette.grey[500] }} />
                  )}
                  <Text ml={1}>{isFolder ? item.displayName : item.name}</Text>
                </Flex>
                <Flex>
                  <Text>{modifiedOn}</Text>
                  <Text
                    color='text.secondary'
                    pl={2}
                  >{`by ${item.createdBy.getFullName()}`}</Text>
                </Flex>
              </MenuItem>
              <Divider flexItem />
            </>
          )
        })}

        {multiSelect && (
          <Button
            variant='contained'
            color='primary'
            onClick={handleConfirmSelection}
            sx={{ width: 150, mt: 2, alignSelf: 'flex-end' }}
          >
            {localizedStrings.addDocuments}
          </Button>
        )}
      </DialogContent>
    </ModalDialogContainer>
  )
}
