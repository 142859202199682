import { IAuthGroup } from '@core/entities/Groups/AuthGroups/IAuthGroup'
import { plainToInstance } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { AuthGroupPermittedActions } from '@core/entities/Groups/AuthGroups/types'

export class AuthGroup extends Entity<IAuthGroup> {
  id: number

  name: string

  userCount?: number

  projectId?: number

  permittedActions: AuthGroupPermittedActions[]

  public static new(payload: unknown): AuthGroup {
    const entity = plainToInstance(AuthGroup, payload)

    return entity
  }

  public hasPermission(action: AuthGroupPermittedActions): boolean {
    return this.permittedActions.includes(action)
  }

  public hasAnyPermissions(actions: Array<AuthGroupPermittedActions>): boolean {
    return actions.some((action) => this.hasPermission(action))
  }
}
