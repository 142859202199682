import { localizedStrings } from '@core/strings'
import React, { useMemo } from 'react'
import { useModal } from 'webapp/components/common/GlobalModal'
import {
  DialogTitle,
  IconButton,
  DialogContent,
  Divider,
  MenuItem,
} from '@common/material'
import { Box, Flex, Text } from '@common/fundamentals'
import ClearIcon from '@mui/icons-material/Clear'
import { Image } from '@fundamentals'
import { TableSecondaryText } from '@common/Table'
import moment from 'moment'
import { ChevronRight } from '@mui/icons-material'
import { PermitDetailsModal } from '@modules/permitDetails/PermitDetailsModal'
import {
  IPermitDuplicate,
  IPermitDuplicatedIn,
} from '@core/entities/permit/PermitImageDuplicates'
import { ModalDialogContainer } from '@common/modal'

interface ComponentType {
  organisationId: number
  imageDuplicates: IPermitDuplicate[]
  timelineDownloadUrls?: Array<string>
}

export const DuplicatedImagePermitModal: React.FC<ComponentType> = ({
  organisationId,
  imageDuplicates,
  timelineDownloadUrls,
}) => {
  const { hideModal, showModal } = useModal()

  const imageDuplicatesUnique = useMemo(
    () =>
      imageDuplicates.reduce((acc, question) => {
        question.duplicatedIn?.forEach((permit) => {
          if (!acc.includes(permit.id)) {
            acc.push(permit.id)
          }
        })
        return acc
      }, []),
    [imageDuplicates],
  )
  return (
    <ModalDialogContainer>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        {localizedStrings.duplicatedImageDetected}
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}
      >
        {imageDuplicates.map((imageDuplicate, index) => {
          const imageUrl = timelineDownloadUrls
            ? timelineDownloadUrls.find((url) =>
                url.includes(imageDuplicate.mediaKey),
              )
            : imageDuplicate?.downloadUrl
          return (
            <Box key={index}>
              <Text fontWeight='bold'>
                {localizedStrings.duplicatedImageDetectedModalDescription}
              </Text>
              {imageDuplicate?.downloadUrl && (
                <>
                  <Image my={2} src={imageUrl} width={170} />
                  <Divider sx={{ pb: 2 }} />
                </>
              )}
              {imageDuplicate?.duplicatedIn?.map((duplicatedIn) => {
                return (
                  <DuplicatePermitItem
                    key={duplicatedIn.id}
                    organisationId={organisationId}
                    duplicatedIn={duplicatedIn}
                  />
                )
              })}
            </Box>
          )
        })}
      </DialogContent>
    </ModalDialogContainer>
  )
}

const DuplicatePermitItem = ({
  organisationId,
  duplicatedIn,
}: {
  organisationId: number
  duplicatedIn: IPermitDuplicatedIn
}) => {
  const { showModal } = useModal()
  // TODO: have backend return the duplicate permit information we need to display the list so that we don't have to fetch a lot of permits

  const {
    id: permitId,
    uploadedBy,
    uploadOn,
    templateName,
    uuid,
    templateVersionId,
  } = duplicatedIn
  const uploadDate = moment(uploadOn).format('DD MMM yyyy')

  return (
    <>
      <MenuItem
        onClick={() =>
          showModal(
            <PermitDetailsModal
              organisationId={organisationId}
              permitId={permitId}
            />,
          )
        }
        sx={{
          my: 2,
          width: '100%',
        }}
        key={permitId}
      >
        <Flex alignItems='center' justifyContent='space-between' width='100%'>
          <Box>
            <Text>{`${templateName} (V${templateVersionId}) - ${uuid}`}</Text>
            <TableSecondaryText>{`Uploaded by: ${uploadedBy}, at ${uploadDate}`}</TableSecondaryText>
          </Box>

          <ChevronRight />
        </Flex>
      </MenuItem>
      <Divider />
    </>
  )
}
