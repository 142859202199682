import { localizedStrings } from '@core/strings'
import {
  SelectFilterInternalArguments,
  SelectFilterValue,
  useSelectFilter,
} from './fundamentals'
import { useDeepCompareEffect } from 'use-deep-compare'
import { useMemo, useState } from 'react'
import { FilterHookArgumentsBase } from '@core/providers/filters'
import { useGetAllProjects } from '@core/react-query/features/projects'
import { usePermitTemplates } from '@core/react-query/features/templates/permitTemplates'

export type PermitTemplateFilterScopes =
  | PermitTemplateFilterOrganisationScope
  | PermitTemplateFilterProjectScope
  | PermitTemplateFilterBusinessUnitScope

export type PermitTemplateFilterOrganisationScope = {
  type: 'ORGANISATION'
  organisationId: number
  projectIds?: number[]
}

export type PermitTemplateFilterProjectScope = {
  type: 'PROJECT'
  organisationId: number
  projectId: number
}

export type PermitTemplateFilterBusinessUnitScope = {
  type: 'BUSINESS_UNIT'
  organisationId: number
  businessUnitId: number
}

type TemplatesFilterArgs<Multiple extends boolean | undefined> =
  FilterHookArgumentsBase<SelectFilterValue<number, Multiple>> & {
    multiple?: Multiple
    scope: PermitTemplateFilterScopes
    whitelist?: number[]
    readOnly?: boolean
  }

export const usePermitTemplatesFilter: <Multiple extends boolean | undefined>(
  arg: TemplatesFilterArgs<Multiple>,
) => SelectFilterInternalArguments<number, Multiple> = ({
  whitelist,
  ...args
}) => {
  const [projectIds, setProjectIds] = useState<Array<number>>([])
  const { data: templates } = usePermitTemplates({
    organisationId: args.scope.organisationId,
    projectIds: projectIds,
    size: 1000,
  })

  useGetAllProjects(
    { organisationId: args.scope.organisationId },
    {
      enabled: args.scope.type === 'BUSINESS_UNIT',
      onSuccess: (projects) => {
        // For some reason this onSuccess was called even when the scope was not business unit
        if (args.scope.type !== 'BUSINESS_UNIT') {
          return
        }
        setProjectIds(projects.map(({ id }) => id))
      },
    },
  )

  useDeepCompareEffect(() => {
    if (args.scope.type === 'ORGANISATION') {
      setProjectIds(args.scope.projectIds || [])
    } else if (args.scope.type === 'PROJECT') {
      setProjectIds([args.scope.projectId])
    }
  }, [args.scope])

  const filteredTemplates = useMemo(() => {
    if (whitelist?.length) {
      return templates?.content.filter((template) =>
        whitelist?.includes(template.id),
      )
    }

    return templates?.content
  }, [templates, whitelist])

  return useSelectFilter({
    ...args,
    options: filteredTemplates?.map((t) => t.id) || [],
    valueToLabel: (v) => filteredTemplates?.find((t) => t.id === v)?.name,
    nothingSelectedText: localizedStrings.allPermits,
    disciplineTag: args.multiple ? 'PERMIT_TEMPLATE' : undefined,
  })
}
