import {
  createMutationKeys,
  createQueryKeys,
  mergeQueryKeys,
} from '@lukemorales/query-key-factory'
import { RequestTypes } from '@core/react-query/features/templates/permitTemplates/requestTypes'
import { APIAxiosInstance } from '@core/utils/legacy-api-axios-instance'
import { createQueryKeyString } from '@core/react-query/queryParams'
import { IPermitTemplate } from '@core/entities/permit/PermitTemplate'
import { PageResponse } from '@core/entities/pagination'

const permitTemplateQueryKeys = createQueryKeys('permit-templates', {
  byOrganisation: ({
    organisationId,
    projectIds,
    page,
    size,
    excludeProjectId,
    term,
  }: RequestTypes['getOrganisationTemplates']) => {
    return {
      queryKey: [organisationId, projectIds, excludeProjectId, term],
      queryFn: async ({ pageParam = 1 }) => {
        const queryString = createQueryKeyString({
          organisationId,
          projectIds,
          page: page ? page : pageParam,
          size,
          excludeProjectId,
          term,
        })
        const { data } = await APIAxiosInstance.get(
          `permits/templates${queryString}`,
        )
        return data as PageResponse<IPermitTemplate>
      },
    }
  },
  page: ({
    organisationId,
    projectIds,
    page,
    size,
    excludeProjectId,
    term,
  }: RequestTypes['getOrganisationTemplates']) => {
    return {
      queryKey: [organisationId, projectIds, excludeProjectId, term],
      queryFn: async () => {
        const queryString = createQueryKeyString({
          organisationId,
          projectIds,
          page,
          size,
          excludeProjectId,
          term,
        })
        const { data } = await APIAxiosInstance.get(
          `permits/templates${queryString}`,
        )
        return data as PageResponse<IPermitTemplate>
      },
    }
  },
  availableTemplates: ({
    projectId,
  }: RequestTypes['getAvailableTemplates']) => {
    return {
      queryKey: ['v2', projectId],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(
          `permits/templates/create-list?projectId=${projectId}`,
        )
        return data
      },
    }
  },
  getRoles: (payload: RequestTypes['getPermitTemplateRoles']) => {
    return {
      queryKey: [payload.templateVersionId],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(
          `permits/templates/${payload.templateId}/versions/${payload.templateVersionId}/roles`,
        )

        return data.roles
      },
    }
  },
})

const permitTemplateMutationsKeys = createMutationKeys('permit-templates', {
  createPermitRole: {
    mutationKey: null,
    mutationFn: async ({
      templateId,
      templateVersionId,
      ...rest
    }: RequestTypes['createPermitRole']) => {
      const { data } = await APIAxiosInstance.post(
        `permits/templates/${templateId}/versions/${templateVersionId}/roles`,
        rest,
      )
      return data
    },
  },
  updatePermitRole: {
    mutationKey: null,
    mutationFn: async ({
      templateId,
      templateVersionId,
      id,
      ...rest
    }: RequestTypes['updatePermitRole']) => {
      const { data } = await APIAxiosInstance.put(
        `permits/templates/${templateId}/versions/${templateVersionId}/roles/${id}`,
        rest,
      )
      return data
    },
  },
  deletePermitRole: {
    mutationKey: null,
    mutationFn: async ({
      templateId,
      templateVersionId,
      id,
    }: RequestTypes['deletePermitRole']) => {
      const { data } = await APIAxiosInstance.delete(
        `permits/templates/${templateId}/versions/${templateVersionId}/roles/${id}`,
      )
      return data
    },
  },
})

export const permitTemplatesKeys = mergeQueryKeys(
  permitTemplateQueryKeys,
  permitTemplateMutationsKeys,
)['permit-templates']
