import { localizedStrings } from '@core/strings'
import React, { FC } from 'react'
import { Divider, Typography, useTheme, alpha } from '@mui/material'
import { Flex, Box, Text } from '@fundamentals'
import { Chip } from '@common/material'
import moment from 'moment'

import ComputerIcon from '@mui/icons-material/Computer'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import {
  getTextForTimelineAction,
  getTimelineStatusColor,
} from '@core/utils/timeline-helpers'
import { AuditResult } from '@core/entities/audit/AuditResult'
import CommentsButton from '@modules/comments/CommentsButton'
import { QuestionWrapperBase } from '@elements/Questions/QuestionWrapperBase'
import { checklistKeys } from '@core/react-query/features/checklists/checklist'
import { useQueryClient } from '@tanstack/react-query'
import useFeatureFlags from '@core/providers/useFeatureFlags'

export type TimelineItemBaseProps = {
  timelineItem: AuditResult
  commentQuestion?: any
}

export const TimelineItemBase: FC<TimelineItemBaseProps> = ({
  timelineItem: { action, user, createdOn, info, metadata, platform },
  children,
  commentQuestion,
}) => {
  const theme: any = useTheme()
  const queryClient = useQueryClient()
  const { flags } = useFeatureFlags(['checklist_comments'])

  const eventTime = metadata?.offlineSubmittedOn || createdOn
  const month = moment(eventTime).format('MMM')
  const year = moment(eventTime).format('YYYY')
  const date = moment(eventTime).format('DD')
  const time = moment(eventTime).format('HH:mm')

  const timeText = (timeString: string) => {
    const month = moment(timeString).format('MMM')
    const year = moment(timeString).format('YYYY')
    const date = moment(timeString).format('DD')
    const time = moment(timeString).format('HH:mm')
    return `${year} ${month} ${date} ${time}`
  }

  const platformText = () => {
    switch (platform) {
      case 'WEB':
        return `| ${localizedStrings.webBrowser}`
      case 'IOS':
        return `| ${localizedStrings.ios}`
      case 'ANDROID':
        return `| ${localizedStrings.android}`
      // The UNKNOWN platform exists because historically we didn't gather the type of device the person was using
      case 'UNKNOWN':
      default:
        return ''
    }
  }

  const platformIcon = () => {
    switch (platform) {
      case 'WEB':
        return <ComputerIcon sx={{ ml: 1 }} fontSize='small' />
      case 'IOS':
        return <PhoneIphoneIcon sx={{ ml: 1 }} fontSize='small' />
      case 'ANDROID':
        return <PhoneAndroidIcon sx={{ ml: 1 }} fontSize='small' />
      default:
        return null
    }
  }

  const timelineElementColors: {
    warning: { normal: 'warning'; alpha: string }
    error: { normal: 'error'; alpha: string }
    success: { normal: 'success'; alpha: string }
    primary: { normal: 'primary'; alpha: string }
    info: { normal: 'info'; alpha: string }
  } = {
    warning: {
      normal: 'warning',
      alpha: alpha(theme.palette.warning.main, 0.04),
    },
    error: {
      normal: 'error',
      alpha: alpha(theme.palette.error.main, 0.04),
    },
    success: {
      normal: 'success',
      alpha: alpha(theme.palette.success.main, 0.04),
    },
    primary: {
      normal: 'primary',
      alpha: alpha(theme.palette.primary.main, 0.04),
    },
    info: {
      normal: 'info',
      alpha: alpha(theme.palette.info.main, 0.04),
    },
  }

  return (
    <Flex sx={{ mb: 2 }}>
      <Box pr={2}>
        <Typography pt={2} fontSize={12} color='text.secondary'>
          {month.toUpperCase()}
        </Typography>
        <Typography>{date}</Typography>
        <Typography fontSize={12} color='text.secondary'>
          {year}
        </Typography>
      </Box>
      <Divider orientation='vertical' flexItem />
      <Box flex={1} ml={2}>
        <Flex
          p='16px 24px 16px 24px'
          borderRadius={1}
          bgcolor={
            getTimelineStatusColor(action, timelineElementColors).cardColor
          }
          flex={1}
          flexDirection='column'
        >
          <Flex justifyContent='space-between'>
            <Box>
              <Typography
                fontSize={12}
                color='text.secondary'
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                {`${time} ${platformText()}`} {platformIcon()}
              </Typography>
              {metadata?.offlineSubmittedOn && (
                <Typography
                  fontSize={12}
                  color='text.secondary'
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  {`${localizedStrings.syncedFromSyncTray} – ${timeText(
                    createdOn,
                  )} `}
                </Typography>
              )}
              {user ? (
                <>
                  <Typography>{`${user.firstName} ${user.lastName}`}</Typography>
                  <Typography fontSize={12} color='text.secondary'>
                    {user.company}
                  </Typography>
                </>
              ) : (
                <Typography pb={2}>
                  {localizedStrings.automatedEvent}
                </Typography>
              )}
            </Box>
            <Box>
              <Chip
                size='small'
                color={
                  getTimelineStatusColor(action, timelineElementColors)
                    .chipColor
                }
                label={getTextForTimelineAction(
                  action,
                  (metadata as AuditResult['metadata'])?.inputType,
                )}
              />
            </Box>
          </Flex>

          {metadata?.commentAnsweredQuestionDataResult && commentQuestion && (
            <>
              <Text
                pt={2}
                fontSize={12}
                color='grey.600'
                sx={{ display: 'flex' }}
              >
                {localizedStrings.question}:
                <Text color='info.main' fontSize={12} sx={{ mx: 1 }}>
                  {`${commentQuestion?.groupIndex + 1}-${
                    commentQuestion?.questionNumber
                  }`}
                </Text>
                {`${commentQuestion?.answer.getBodyFormattedText()}`}
              </Text>
              <Text
                fontSize={12}
                color='grey.600'
                my={1}
                sx={{ display: 'flex' }}
              >
                {localizedStrings.comment}:
                <Text fontWeight='bold' fontSize={12} sx={{ ml: 1 }}>
                  {info}
                </Text>
              </Text>
            </>
          )}

          {info && !metadata.commentAnsweredQuestionDataResult && (
            <Text pt={1} fontWeight='bold' fontSize={12}>
              {info}
            </Text>
          )}

          {children}
          {metadata?.commentAnsweredQuestionDataResult &&
            commentQuestion &&
            flags.checklist_comments && (
              <Box mt={0.5}>
                <CommentsButton
                  subType='QUESTION'
                  subTypeId={metadata.commentAnsweredQuestionDataResult.id}
                  label={localizedStrings.openAllComments}
                  showIcon={false}
                  onCreateComment={(referenceObjectId) => {
                    queryClient.invalidateQueries({
                      queryKey: checklistKeys.byId({
                        checklistId: referenceObjectId,
                      })._ctx.timeline.queryKey,
                    })
                  }}
                  subTypeComponent={
                    <QuestionWrapperBase
                      groupIndex={commentQuestion.groupIndex}
                      questionNumber={commentQuestion.questionNumber}
                      questionIndex={commentQuestion.answerIndex}
                      question={metadata.commentAnsweredQuestionDataResult.toPlain()}
                      readOnly
                    />
                  }
                />
              </Box>
            )}
        </Flex>
      </Box>
    </Flex>
  )
}
