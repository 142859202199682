import { Exclude, plainToInstance, Type } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { User } from '@core/entities/user'
import { IComment } from './IComment'
import {
  CommentReferenceObjectTypes,
  CommentSecondaryReferenceObjectTypes,
} from './types'
import moment from 'moment'

export class Comment extends Entity<IComment> {
  id: number

  organisationId: number

  projectId: number

  referenceObjectId: number

  referenceObjectType: CommentReferenceObjectTypes

  message: string

  secondaryReferenceObjectId: number

  secondaryReferenceObjectIdText: string

  secondaryReferenceObjectType: CommentSecondaryReferenceObjectTypes

  createdOn: string

  mediaUrls: string[]

  @Type(() => User)
  createdBy: User

  @Exclude({ toPlainOnly: true })
  mediaUrlsWithType: Array<{
    getUrl: string
    type: string
  }> = []

  public getInfo(): string {
    return `${this.createdBy.getFullName()} | ${moment(this.createdOn).format(
      'DD MMM YYYY HH:mm',
    )}`
  }

  public static new(payload: unknown): Comment {
    const entity = plainToInstance(Comment, payload)

    return entity
  }
}
