import React from 'react'
import { Button } from '@common/material'
import { localizedStrings } from '@core/strings'
import { Box, Flex } from '@fundamentals'
import { PermitActionCard } from '../PermitActionCard'
import { Permit } from '@core/entities/permit/Permit'
import moment from 'moment'
import { useModal } from '@common/GlobalModal'
import RejectResumePermitRequestModal from '../modals/RejectResumePermitRequestModal'
import {
  useApproveResumePermitRequest,
  useRejectResumePermitRequest,
} from '@core/react-query/features/permits/permit'
import { useToast } from '@core/toast'
import {
  ApprovePermitDetailsSidebarData,
  RejectResumePermitSubmitData,
} from '../types'

type PermitSuspensionCardProps = {
  permit: Permit
}
export const PermitSuspensionCard: React.FC<PermitSuspensionCardProps> = ({
  permit,
}) => {
  const { showModal, hideModal } = useModal()
  const toast = useToast()
  const approveResumeRequest = useApproveResumePermitRequest()
  const rejectResumeRequest = useRejectResumePermitRequest()

  if (
    !(
      permit?.permitSuspensionResult?.isPending() &&
      permit.hasPermission('APPROVE_SUSPENSION_RESUME_REQUEST')
    )
  )
    return null

  const { suspendedBy, startedOn } = permit.permitSuspensionResult
  const datetimeString = moment(startedOn).format('D MMMM YYYY HH:mm')

  const handleApproveResumeRequest = (
    data: ApprovePermitDetailsSidebarData,
  ) => {
    approveResumeRequest.mutate(data, {
      onSuccess: () => {
        hideModal()
        toast.success(localizedStrings.resumeRequestApproved)
      },
      onError: (e) => toast.error(e.message),
    })
  }

  const handleRejectResumeRequest = (data: RejectResumePermitSubmitData) => {
    rejectResumeRequest.mutate(data, {
      onSuccess: () => {
        hideModal()
        toast.success(localizedStrings.resumeRequestRejected)
      },
      onError: (e) => toast.error(e.message),
    })
  }

  const handleApprove = () => {
    handleApproveResumeRequest({ permitId: permit.id })
  }

  const handleReject = (comment: string) => {
    handleRejectResumeRequest({ permitId: permit.id, comment })
  }

  return (
    <PermitActionCard
      title={localizedStrings.resumePermitRequest}
      info={
        <Box>
          <Box>{`${
            localizedStrings.suspendedBy
          }: ${suspendedBy.getFullName()}`}</Box>
          <Box>{`${localizedStrings.date}: ${datetimeString}`}</Box>
        </Box>
      }
      BottomComponent={
        <Flex gap={1}>
          <Button
            variant='outlined'
            color='error'
            fullWidth
            onClick={() => {
              showModal(
                <RejectResumePermitRequestModal
                  onConfirmReject={handleReject}
                />,
              )
            }}
          >
            {localizedStrings.reject}
          </Button>
          <Button
            variant='contained'
            color='primary'
            fullWidth
            onClick={handleApprove}
          >
            {localizedStrings.approve}
          </Button>
        </Flex>
      }
      sx={{ mb: 1.5, borderColor: 'primary.main', borderWidth: 2 }}
    />
  )
}
