import React from 'react'
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined'
import { Button, ButtonProps } from '@mui/material'
import { useComments } from '@core/react-query/features/comments/hooks/CommentsContext'
import CommentsModal from '@modules/comments/CommentsModal'
import { CommentSecondaryReferenceObjectTypes } from '@core/entities/comments'
import { localizedStrings } from '@core/strings'

type CommentsButtonProps = ButtonProps & {
  subTypeId?: string
  subTypeComponent: React.ReactNode
  subType: CommentSecondaryReferenceObjectTypes
  label?: string
  showIcon?: boolean
  onCreateComment?: (referenceObjectId: number) => void
}

const CommentsButton: React.FC<CommentsButtonProps> = ({
  subTypeId,
  subType,
  subTypeComponent,
  label,
  showIcon = true,
  onCreateComment,
  ...props
}) => {
  const { commentsBySubTypeId } = useComments()
  const numberOfComments = commentsBySubTypeId?.[subTypeId]?.length
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <CommentsModal
        subTypeIdText={subTypeId}
        subType={subType}
        open={open}
        subTypeComponent={subTypeComponent}
        onDismiss={() => setOpen(false)}
        onCreateComment={onCreateComment}
      />
      <Button
        variant='outlined'
        size='small'
        startIcon={showIcon && <CommentOutlinedIcon />}
        onClick={() => setOpen(true)}
        {...props}
      >
        {label || localizedStrings.commentsCount(numberOfComments || 0)}
      </Button>
    </>
  )
}

export default CommentsButton
