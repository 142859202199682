import { localizedStrings } from '@core/strings'
import React, { useMemo } from 'react'
import { Box, Text } from '@fundamentals'
import {
  AttachmentTypes,
  QuestionProps,
} from '@core/components/templateAnswersForm'
import { useWatch } from 'react-hook-form'
import CaptureSignature from '@common/CaptureSignature'
import { AttachmentInput } from './AttachmentInput'
import { Section } from '@common/Section'
import CommentsButton from '@modules/comments/CommentsButton'
import { useComments } from '@core/react-query/features/comments/hooks/CommentsContext'
import CommentItem from '@modules/comments/CommentItem'
import { Comment } from '@core/entities/comments'
import { QuestionWrapperBase } from '@elements/Questions/QuestionWrapperBase'
import useFeatureFlags from '@core/providers/useFeatureFlags'
import WarningCard from '@modules/permitDetails/WarningCard'

const QuestionWrapper: React.FC<QuestionProps> = ({
  groupIndex,
  questionIndex,
  questionNumber,
  answer,
  readOnly,
  questionsFormKey,
  warnings = [],
}) => {
  const { commentsBySubTypeId, commentsQuery } = useComments()
  const { flags } = useFeatureFlags(['checklist_comments', 'permit_warnings'])

  const question =
    answer ||
    useWatch({
      name: `${questionsFormKey}.${groupIndex}.data.${questionIndex}`,
    })

  const attachmentTypes = useMemo(() => {
    if (question.questionType === 'DOCUMENT') {
      return [AttachmentTypes.PDF]
    }
    if (question.requiresProof === 'NOT_REQUIRED') return
    if (question.requireProofFromCamera) return

    return [AttachmentTypes.IMAGE, AttachmentTypes.PDF]
  }, [
    question.questionType,
    question.requireProofFromCamera,
    question.requiresProof,
  ])

  const attachments = useMemo(() => {
    return [
      ...(question.submissionProofs?.map((proof) => ({
        type: 'image',
        ...proof,
      })) || []),
      ...(question.submissionDocuments?.map((document) => ({
        type: 'application/pdf',
        ...document,
      })) || []),
    ]
  }, [attachmentTypes])

  const comments = commentsQuery && commentsBySubTypeId[question.id]
  const latestComment = comments?.[comments.length - 1]

  return (
    <QuestionWrapperBase
      groupIndex={groupIndex}
      questionNumber={questionNumber}
      questionIndex={questionIndex}
      attachmentTypes={attachmentTypes}
      attachments={attachments}
      question={question}
      readOnly={readOnly}
      actionButton={
        readOnly &&
        commentsQuery &&
        flags.checklist_comments && (
          <CommentsButton
            subType='QUESTION'
            subTypeId={question.id}
            subTypeComponent={
              <QuestionWrapperBase
                groupIndex={groupIndex}
                questionNumber={questionNumber}
                questionIndex={questionIndex}
                question={question}
                readOnly
              />
            }
          />
        )
      }
    >
      {readOnly && (
        <>
          {(attachmentTypes || !!attachments.length) && (
            <AttachmentInput
              heading={localizedStrings.proofs}
              inputProps={{ value: attachments }}
              dataTest={`${question.id}-attachment-input`}
              readOnly
              mt={2}
            />
          )}
          {flags.permit_warnings &&
            warnings?.map((warning, key) => (
              <Box mt={2} key={key}>
                <WarningCard warning={warning} />
              </Box>
            ))}
          {(question.localSignature || question.signatureUrl) && (
            <CaptureSignature
              inputProps={{
                value: question.localSignature
                  ? URL.createObjectURL(question.localSignature)
                  : question.signatureUrl,
              }}
              optional={question.requiresSignature === 'OPTIONAL'}
              dataTest={question.id}
              readOnly
            />
          )}
          {question?.questionAdditionalInformation && (
            <Section
              label={localizedStrings.questionAdditionalInformation(
                question.requiresAdditionalInformation === 'OPTIONAL',
              )}
              data-test={`${question.id}-${'QUESTION_ADDITIONAL_INFORMATION'}`}
              sx={{ mt: 2 }}
            >
              <Text> {question?.questionAdditionalInformation}</Text>
            </Section>
          )}
        </>
      )}
      {latestComment && readOnly && flags.checklist_comments && (
        <Box mt={3}>
          <CommentItem comment={Comment.new(latestComment)} />
        </Box>
      )}
    </QuestionWrapperBase>
  )
}

export { QuestionWrapper }
