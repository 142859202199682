import _ from 'lodash'
import { localizedStrings } from '@core/strings'
import React, { useMemo } from 'react'
import { Box, Flex, Text } from '@fundamentals'
import { QuestionWrapper } from './QuestionWrapper'
import { Card, Divider, Button } from '@common/material'
import { useGetPermitImageDuplicates } from '@core/react-query/features/permits/image-duplicates'
import { useTheme } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import { DuplicatedImagePermitModal } from '@modules/permitDetails/modals/DuplicatedImagePermitModal'
import { useModal } from '@common/GlobalModal'
import useFeatureFlags from '@core/providers/useFeatureFlags'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { QuestionGroupProps } from '@core/components/templateAnswersForm'
import { ConditionalQuestionGroup } from './ConditionalQuestionGroup'

const QuestionGroup: React.FC<QuestionGroupProps> = ({
  questionGroup: { conditionalQuestionGroup, ...questionGroup },
  questionsFormKey = 'questions',
  groupIndex,
  permitId,
  organisationId,
  readOnly,
}) => {
  const { flags } = useFeatureFlags(['web_duplicate_images'])
  const theme: any = useTheme()
  const { showModal } = useModal()
  const { data: permitImageDuplicates } = useGetPermitImageDuplicates(
    {
      permitId,
    },
    {
      enabled: !!flags.web_duplicate_images && !!permitId,
    },
  )

  const formContext = useFormContext()

  const hiddenQuestionIds: Array<string> = !formContext
    ? null
    : useWatch({
        name: 'hiddenQuestionIds',
      })

  const questionIndicesById = useMemo(() => {
    return questionGroup.data.reduce((acc, question, index) => {
      acc[question.id] = index
      return acc
    }, {})
  }, [])

  const visibleQuestionIdsSet = useMemo(() => {
    const hiddenQuestionIdsSet = new Set(
      hiddenQuestionIds ||
        questionGroup.data
          .filter((question) => question.isHidden)
          .map((question) => question.id),
    )
    const visibleQuestionIds = questionGroup.data.reduce((acc, question) => {
      if (!hiddenQuestionIdsSet.has(question.id)) acc.add(question.id)

      return acc
    }, new Set<string>())

    return visibleQuestionIds
  }, [hiddenQuestionIds])

  const lastQuestionIndex = useMemo(() => {
    return _.findLastIndex(questionGroup.data, (question) => {
      return visibleQuestionIdsSet.has(question.id)
    })
  }, [visibleQuestionIdsSet])

  const isQuestionGroupHidden =
    (hiddenQuestionIds === null && questionGroup?.isHidden) ||
    hiddenQuestionIds?.includes(questionGroup.id) ||
    (visibleQuestionIdsSet?.size === 0 && !conditionalQuestionGroup)

  if (isQuestionGroupHidden) return null

  const hasErrors = !!formContext?.formState.errors?.questions?.[groupIndex]

  const questionIdsWithDuplicateImages = permitImageDuplicates?.duplicates?.map(
    (duplicate) => duplicate.questionId,
  )

  const questionGroupHasDuplicateImages = questionGroup.data.some((question) =>
    questionIdsWithDuplicateImages?.includes(question.id),
  )

  const getBorderColor = () => {
    if (questionGroupHasDuplicateImages) return theme.palette.error.main
  }

  return (
    <Card
      sx={{
        mb: 2,
        p: 3,
        borderColor: flags.web_duplicate_images && getBorderColor(),
      }}
      data-test={`question-group-${groupIndex}`}
      data-id={questionGroup.id}
    >
      {questionGroup.title && (
        <Text
          sx={{
            color: 'info.light',
            fontSize: '12px',
            mr: 2,
            mb: 1,
          }}
        >
          {questionGroup.title}
        </Text>
      )}

      {conditionalQuestionGroup &&
        (!formContext ? (
          <ConditionalQuestionGroup
            conditionalQuestionGroup={conditionalQuestionGroup}
            readOnly={readOnly}
          />
        ) : (
          <Controller
            name={`${questionsFormKey}.${groupIndex}.conditionalQuestionGroup.submissionText`}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => {
              return (
                <Box
                  sx={{
                    mt: error ? 2 : 0,
                    outlineWidth: error ? '2px' : '0',
                    // @ts-ignore
                    outlineColor: theme.palette.error.main,
                    outlineStyle: 'solid',
                    outlineOffset: '5px',
                    borderRadius: 0.1,
                    transition: 'all 0.3s',
                    padding: error ? 1.5 : 0,
                    pb: error ? 2 : 0,
                  }}
                >
                  <ConditionalQuestionGroup
                    conditionalQuestionGroup={conditionalQuestionGroup}
                    inputProps={field}
                    readOnly={readOnly}
                  />
                </Box>
              )
            }}
          />
        ))}
      {questionGroup.data.map((question, questionIndex) => {
        if (!visibleQuestionIdsSet?.has(question.id)) return null
        const isLastQuestion = questionIndex === lastQuestionIndex

        return (
          <Box key={question.id} mb={!isLastQuestion && 2}>
            <QuestionWrapper
              groupIndex={groupIndex}
              questionIndex={questionIndex}
              questionNumber={questionIndicesById[question.id] + 1}
              answer={!formContext && question}
              readOnly={readOnly}
              questionsFormKey={questionsFormKey}
            />
          </Box>
        )
      })}
      {flags.web_duplicate_images && questionGroupHasDuplicateImages && (
        <Box pt={2}>
          <Divider />
          <Flex pt={2} alignItems='center'>
            <InfoOutlined color='error' />
            <Text pl={1} fontSize={14} color='error'>
              {localizedStrings.duplicatedImageDetected}
            </Text>
          </Flex>
          <Button
            sx={{ mt: 2 }}
            variant='contained'
            color='error'
            fullWidth
            onClick={() => {
              const questionDuplicates =
                permitImageDuplicates?.duplicates?.filter((duplicate) =>
                  questionGroup.data.find(
                    (question) => duplicate.questionId === question.id,
                  ),
                )
              showModal(
                <DuplicatedImagePermitModal
                  imageDuplicates={questionDuplicates}
                  organisationId={organisationId}
                />,
              )
            }}
          >
            {localizedStrings.viewPermits}
          </Button>
        </Box>
      )}
    </Card>
  )
}

export { QuestionGroup }
